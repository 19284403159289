import * as React from "react";
const DatePicker = require("react-mobile-datepicker");
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  TextInput,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import {
  Input,
  Button,
  CheckBox,
  ButtonGroup,
  Badge,
} from "react-native-elements";
const moment = require("moment");
import formatNumber from "format-number";
import { block } from "react-native-reanimated";
import { DrawerContentScrollView } from "@react-navigation/drawer";
const numberFormat = formatNumber({
  round: 0,
});

export default function TeibanDetailScreen(props: any) {
  const { navigation, functions } = props;

  const [billing_date, setBillingDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [type, setType] = useState(0);
  const [to_org, setToOrg] = useState(0);
  const [from_org, setFromOrg] = useState(0);
  const [to_org_name, setToOrgName] = useState("未選択");
  const [from_org_name, setFromOrgName] = useState("未選択");
  const [work, setWork] = useState("");
  const [work_type_id, setWorkTypeId] = useState(0);
  const [work_type_name, setWorkTypeName] = useState("未選択");
  const [unit_price, setUnitPrice] = useState("0");
  const [quantity_type, setQuantityType] = useState("");
  const [quantity, setQuantity] = useState("0");
  const [total, setTotal] = useState("0");
  const [errstr, setErrstr] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dt, setDt] = useState(new Date());

  const [errWork, setErrWork] = useState("");
  const [errUnitPrice, setErrUnitPrice] = useState("");
  const [errQuantity, setErrQuantity] = useState("");
  const [errToOrg, setErrToWork] = useState("");
  const [errFromOrg, setErrFromWork] = useState("");
  const [errWorkType, setErrWorkType] = useState("");

  function handleClick() {
    setIsPickerOpen(true);
  }
  function handleCancel() {
    setIsPickerOpen(false);
  }
  function handleSelect(tm: any) {
    setDt(tm);
    setBillingDate(moment(tm).format("YYYY-MM-DD"));
    setIsPickerOpen(false);
  }

  function validate() {
    let flag = true;

    //仕事
    if (work == "") {
      setErrWork("入力してください");
      flag = false;
    } else {
      setErrWork("");
    }
    //単価
    if (unit_price == "") {
      setErrUnitPrice("入力してください");
      flag = false;
    } else if (!isFinite(parseInt(unit_price))) {
      setErrUnitPrice("数値で入力してください");
      flag = false;
    } else if (parseInt(unit_price) <= 0) {
      setErrUnitPrice("1以上で入力してください");
      flag = false;
    } else {
      setErrUnitPrice("");
    }
    //数量
    if (quantity == "") {
      setErrQuantity("入力してください");
      flag = false;
    } else if (!isFinite(parseInt(quantity))) {
      setErrQuantity("数値で入力してください");
      flag = false;
    } else if (parseInt(quantity) <= 0) {
      setErrQuantity("1以上で入力してください");
      flag = false;
    } else {
      setErrQuantity("");
    }
    //請求先
    if (to_org == 0) {
      setErrToWork("選択してください");
      flag = false;
    } else {
      setErrToWork("");
    }
    //請求元
    if (from_org == 0) {
      setErrFromWork("選択してください");
      flag = false;
    } else {
      setErrFromWork("");
    }
    //仕事種別
    if (work_type_id == 0) {
      setErrWorkType("選択してください");
      flag = false;
    } else {
      setErrWorkType("");
    }

    return flag;
  }

  useEffect(() => {
    //setIsLoading(true);
    if (props.route.params.id > 0) {
      let url = window.baseUrl + "/teiban/" + props.route.params.id;
      window
        .axios({
          method: "get",
          url,
          data: {},
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          setType(data.type);
          setToOrg(data.to_org);
          setFromOrg(data.from_org);
          setToOrgName(data.to_org_name);
          setFromOrgName(data.from_org_name);
          setWorkTypeId(data.work_type_id);
          setWorkTypeName(data.work_type_name);
          setWork(data.work ? data.work : "");
          setUnitPrice(data.unit_price);
          setQuantity(data.quantity);
          setTotal(data.total);
        })
        .catch((err: any) => {
          window.functions.logout();
        });
      setIsLoading(false);
    } else {
      setType(props.route.params.type);
      setIsLoading(false);
    }
  }, []);

  let methodType = props.route.params.id == 0 ? "post" : "put";
  let postUrl =
    props.route.params.id == 0 ? "/teiban" : "/teiban/" + props.route.params.id;

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.itemViewStyle}>
            <Text style={{}}>請求先</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate("SelectTeibanOrganisationScreen", {
                  id: to_org,
                  setOrg: setToOrg,
                  setOrgName: setToOrgName,
                  backScreen: "TeibanDetailScreen",
                  type: type,
                  mode: "to_org",
                });
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {to_org_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
            <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
              {errToOrg}
            </Text>
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>請求元</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate("SelectTeibanOrganisationScreen", {
                  id: from_org,
                  setOrg: setFromOrg,
                  setOrgName: setFromOrgName,
                  backScreen: "TeibanDetailScreen",
                  range: "belong",
                  type: type,
                  mode: "from_org",
                });
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {from_org_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
            <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
              {errFromOrg}
            </Text>
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>分類（仕事）</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate("SelectTeibanWorkTypeScreen", {
                  id: work_type_id,
                  setWorkTypeId,
                  setWorkTypeName,
                  backScreen: "TeibanDetailScreen",
                  type: type,
                });
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {work_type_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
            <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
              {errWorkType}
            </Text>
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>仕事</Text>
            <Input
              style={styles.itemStyleEdit}
              value={work}
              onChangeText={(value) => {
                setWork(value);
              }}
              errorMessage={errWork}
            />
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>単価／時給</Text>
            <Input
              keyboardType={"number-pad"}
              style={styles.itemStyleEdit}
              value={unit_price}
              errorMessage={errUnitPrice}
              onChangeText={(value: any) => {
                setUnitPrice(value);
                let val = parseInt(value) * parseInt(quantity);
                setTotal(val.toString());
              }}
              onFocus={() => {
                if (unit_price == "0") {
                  setUnitPrice("");
                }
              }}
              onBlur={() => {
                if (unit_price == "") {
                  setUnitPrice("0");
                }
              }}
            />
          </View>
          <View style={styles.itemViewStyle}>
            <Text style={{}}>数量／時間</Text>
            <Input
              keyboardType={"number-pad"}
              style={styles.itemStyleEdit}
              value={quantity}
              errorMessage={errQuantity}
              onChangeText={(value: any) => {
                setQuantity(value);
                let val = parseInt(unit_price) * parseInt(value);
                setTotal(val.toString());
              }}
              onFocus={() => {
                if (quantity == "0") {
                  setQuantity("");
                }
              }}
              onBlur={() => {
                if (quantity == "") {
                  setQuantity("0");
                }
              }}
            />
          </View>

          <View style={styles.itemViewStyle}>
            <Text style={{}}>合計</Text>
            <Text style={styles.itemStyle}>
              <Text style={{ ...styles.itemStyle }}>
                {numberFormat(Number(total))}
              </Text>
            </Text>
          </View>

          <Button
            title="更新する"
            type="outline"
            titleStyle={{ color: "#fff" }}
            buttonStyle={{ backgroundColor: "#3cb371" }}
            style={{ marginTop: 30 }}
            onPress={() => {
              if (!validate()) return;
              window
                .axios({
                  method: methodType,
                  url: window.baseUrl + postUrl,
                  data: {
                    type,
                    billing_date,
                    to_org,
                    from_org,
                    work_type_id,
                    work,
                    unit_price,
                    quantity,
                    total,
                    source_id: props.route.params.id,
                  },
                  headers: {
                    Authorization: "Bearer " + window.accessToken,
                  },
                })
                .then((ret: any) => {
                  window.reloadFlag = true;
                  navigation.navigate("TeibanScreen");
                })
                .catch((err: any) => {
                  window.functions.logout();
                });
            }}
          />

          {props.route.params.id > 0 && (
            <Button
              title="削除する"
              titleStyle={{ fontSize: 15 }}
              disabled={false}
              buttonStyle={{
                height: 30,
                backgroundColor: "#f00",
                marginTop: 30,
              }}
              onPress={() => {
                if (!confirm("削除してもよいですか？")) return;
                window
                  .axios({
                    method: "delete",
                    url: window.baseUrl + "/teiban/" + props.route.params.id,
                    headers: {
                      Authorization: "Bearer " + window.accessToken,
                    },
                  })
                  .then(() => {
                    window.reloadFlag = true;
                    navigation.navigate("TeibanScreen");
                  });
              }}
            />
          )}
        </View>
      </ScrollView>

      {isLoading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            flex: 1,
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
