import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  ScrollView,
} from "react-native";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import styled from "styled-components";

import { useNavigation } from "@react-navigation/native";

export default function AccountScreen() {
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {});
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 30,
          }}
        >
          <Button
            type={"solid"}
            style={{}}
            title="ログアウトする"
            onPress={() => {
              //if (window.confirm("ログアウトしてもよろしいですか？")) {
              window.functions.logout();
              //}
            }}
          />
          <Button
            type={"outline"}
            style={{ marginTop: 30 }}
            title="ホームに戻る"
            onPress={() => {
              window.navi.navigate("HomeScreen");
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
});
