import { StatusBar } from "expo-status-bar";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Storage from "react-native-storage";
import AsyncStorage from "@react-native-community/async-storage";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import { useWindowDimensions } from "react-native";

declare global {
  interface Window {
    width: number;
    height: number;
    axios: any;
    baseUrl: string;
    storage: any;
    accessToken: any;
    navi: any;
    functions: {
      setIsAuth: any;
      isAuth: any;
      openDrawer: any;
      openFilter: any;
      reloadList: any;
      teibanAtOnce: any;
      logout: any;
      openSalesTarget: any;
      unset: any;
    };
    commentList: any;
    reloadFlag: boolean;
    currentTeibanType: number;

    invoices: any;

    refreshFlag: boolean;
  }
}

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;
window.baseUrl = "https://xs591901.xsrv.jp/api";
window.storage = new Storage({
  storageBackend: AsyncStorage,
  defaultExpires: 1000 * 3600 * 24 * 365,
});
window.accessToken = "";
window.functions = {
  setIsAuth: null,
  isAuth,
  openDrawer: null,
  openFilter: null,
  reloadList: null,
  teibanAtOnce: null,
  logout: null,
  unset: null,
};
window.commentList = null;
window.reloadFlag = false;
window.currentTeibanType = 0;

window.refreshFlag = false;

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const { width, height } = useWindowDimensions();
  window.width = width;
  window.height = height;

  window.functions.logout = function () {
    window
      .axios({
        method: "post",
        url: window.baseUrl + "/logout",
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then((res: any) => {
        const { data } = res;
        if (!data.result) {
          alert("ログアウトに失敗しました");
        } else {
          window.storage.remove({
            key: "accessToken",
          });
          window.accessToken = "";
          window.functions.setIsAuth(false);
        }
      })
      .catch((err: any) => {
        window.storage.remove({
          key: "accessToken",
        });
        window.accessToken = "";
        window.functions.setIsAuth(false);
      });
  };

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
}

async function isAuth() {
  return await window.storage
    .load({ key: "accessToken" })
    .catch((err: any) => null);
}
