import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Loading: {
        LoadingScreen: "loading",
      },
      Login: {
        screens: {
          LoginScreen: "login",
        },
      },
      Teiban: {
        screens: {
          TeibanScreen: "teiban",
        },
      },
      Root: {
        screens: {
          Drawer: {
            screens: {
              Home: {
                screens: {
                  HomeScreen: "home",
                },
              },
              Invoice: {
                screens: {
                  InvoiceScreen: "invoice",
                  InvoiceDetailScreen: "invoice/detail",
                  SelectOrganisationScreen: "invoice/select_organisation",
                },
              },
              Order: {
                screens: {
                  OrderScreen: "order",
                  OrderDetailScreen: "order/detail",
                  SelectOrderOrganisationScreen: "order/select_organisation",
                },
              },
              Receipt: {
                screens: {
                  ReceiptScreen: "receipt",
                  ReceiptDetailScreen: "receipt/detail",
                },
              },
              Work: {
                screens: {
                  WorkScreen: "work",
                  WorkDetailScreen: "work/detail",
                },
              },
            },
          },
        },
      },
      NotFound: "*",
    },
  },
};
