import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useState } from "react";
import { ColorSchemeName, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";

import NotFoundScreen from "../screens/NotFoundScreen";
import { RootStackParamList } from "../types";
import LoginNavigator from "./LoginNavigator";
import BottomTabNavigator from "./BottomTabNavigator";
import LinkingConfiguration from "./LinkingConfiguration";
import LoadingScreen from "../screens/LoadingScreen";

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const functions = { setIsAuth };

  window.functions.setIsAuth = setIsAuth;

  window.functions.isAuth().then((token: any) => {
    if (token != "" && token != null) {
      window.accessToken = token;
      setIsAuth(true);
      setIsLoading(false);
    } else {
      window.accessToken = "";
      setIsAuth(false);
      setIsLoading(false);
    }
  });

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isLoading && (
        <Stack.Screen
          name="Loading"
          component={LoadingScreen}
          options={{ title: "Now Loading" }}
        />
      )}
      {!isAuth && (
        <Stack.Screen name="Login">
          {() => <LoginNavigator functions={functions} />}
        </Stack.Screen>
      )}
      {isAuth && (
        <Stack.Screen name="Root">{() => <BottomTabNavigator />}</Stack.Screen>
      )}
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
    </Stack.Navigator>
  );
}
