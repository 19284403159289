import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import Select from "react-select";
import { FontAwesome } from "@expo/vector-icons";

import { useNavigation } from "@react-navigation/native";

export default function SelectOrganisationScreen(props: any) {
  const [parents, setParents] = useState([]);
  const [parent_value, setParentValue] = useState({});
  const [organisations, setOrganisations] = useState([]);

  const navigation = useNavigation();

  async function init() {
    let { data: org } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/organisation/" +
        props.route.params.id +
        "?type=" +
        props.route.params.type +
        "&mode=" +
        props.route.params.mode,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (org && org.parent) {
      setParentValue({
        label: org.parent.name,
        value: org.parent.id,
      });
    }

    let { data } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/organisation?id=0" +
        "&type=" +
        props.route.params.type +
        "&mode=" +
        props.route.params.mode,
      //(props.route.params.range ? "&range=" + props.route.params.range : "")
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    let newList = [];
    if (data) {
      newList = data.map(function (item: any) {
        return { label: item.name, value: item.id };
      });
      setParents(newList);
    }

    /*
    if (props.route.params.id == 0) {
      setParentValue({
        label: newList[0].label,
        value: newList[0].value,
      });
    }
    let { data: org_list } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/organisation?id=" +
        (props.route.params.id == 0 ? newList[0].value : org.parent.id) +
        (props.route.params.range ? "&range=" + props.route.params.range : ""),
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (org_list) {
      setOrganisations(org_list);
    }
    */

    setParentValue({
      label: newList[0].label,
      value: newList[0].value,
    });
    let { data: org_list } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/organisation?id=" +
        newList[0].value +
        "&type=" +
        props.route.params.type +
        "&mode=" +
        props.route.params.mode +
        (props.route.params.range ? "&range=" + props.route.params.range : ""),
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (org_list) {
      setOrganisations(org_list);
    }
  }

  async function changeOrganisation(id: number) {
    let { data: org_list } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/organisation?id=" +
        id +
        "&type=" +
        props.route.params.type +
        "&mode=" +
        props.route.params.mode +
        (props.route.params.range &&
        props.route.params.mode == "from_org" &&
        id == -1
          ? "&range=" + props.route.params.range
          : ""),
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (org_list) {
      setOrganisations(org_list);
    }
  }

  useEffect(() => {
    init();
    window.functions.unset = () => {
      props.route.params.setOrg(0);
      props.route.params.setOrgName("未選択");
      navigation.navigate(props.route.params.backScreen);
    };
  }, []);

  function keyExtractor(item: any, index: any) {
    return item.id.toString();
  }

  function renderItem({ item }: any) {
    return (
      <View
        style={{
          height: 50,
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            props.route.params.setOrg(item.id);
            props.route.params.setOrgName(item.label_name);
            navigation.navigate(props.route.params.backScreen);
          }}
        >
          <Text style={{ fontSize: 12, color: "#aaa" }}>
            {item.parent_name}
          </Text>
          <Text style={{ fontSize: 17 }}>{item.name}</Text>
          {item.id == props.route.params.id && (
            <FontAwesome
              name={"check"}
              size={24}
              style={{
                color: "rgb(32, 137, 220)",
                position: "absolute",
                right: 10,
                top: 10,
              }}
            />
          )}
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={{ width: "100%", padding: 5, zIndex: 3000 }}>
        <Select
          options={parents}
          onChange={(val: any) => {
            setParentValue(val);
            changeOrganisation(val.value);
          }}
          value={parent_value}
        />
      </View>
      <FlatList
        style={{ width: "100%", backgroundColor: "#fff" }}
        keyExtractor={keyExtractor}
        data={organisations}
        renderItem={renderItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
});
