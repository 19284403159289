import * as React from "react";
const DatePicker = require("react-mobile-datepicker");
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  TextInput,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import {
  Input,
  Button,
  CheckBox,
  ButtonGroup,
  Badge,
} from "react-native-elements";
const moment = require("moment");
import formatNumber from "format-number";
import { block } from "react-native-reanimated";
import { DrawerContentScrollView } from "@react-navigation/drawer";
const numberFormat = formatNumber({
  round: 0,
});

export default function ReceiptDetailScreen(props: any) {
  const { navigation, functions } = props;

  const [billing_date, setBillingDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [to_org, setToOrg] = useState(0);
  const [from_org, setFromOrg] = useState(0);
  const [to_org_name, setToOrgName] = useState("未選択");
  const [from_org_name, setFromOrgName] = useState("未選択");
  const [work, setWork] = useState("");
  const [work_type_id, setWorkTypeId] = useState(0);
  const [work_type_name, setWorkTypeName] = useState("未選択");
  const [unit_price, setUnitPrice] = useState("0");
  const [quantity_type, setQuantityType] = useState("");
  const [quantity, setQuantity] = useState("0");
  const [total, setTotal] = useState("0");
  const [state, setState] = useState(0);
  const [state_label, setStateLabel] = useState("");
  const [draft, setDraft] = useState(0);
  const [errstr, setErrstr] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [listHeight, setListHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dt, setDt] = useState(new Date());

  const [unread, setUnread] = useState(0);

  const stateColor: string[] = [
    "#a0d8ef",
    "#9ae69a",
    "#f19ca7",
    "#bdafdb",
    "#f4a460",
  ];

  useEffect(() => {
    //setIsLoading(true);
    if (props.route.params.id > 0) {
      window
        .axios({
          method: "get",
          url: window.baseUrl + "/receipt/" + props.route.params.id,
          data: {},
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          setBillingDate(data.billing_date);
          setToOrg(data.to_org);
          setFromOrg(data.from_org);
          setToOrgName(data.to_org_name);
          setFromOrgName(data.from_org_name);
          setWorkTypeId(data.work_type_id);
          setWorkTypeName(data.work_type_name);
          setWork(data.work ? data.work : "");
          setUnitPrice(data.unit_price);
          setQuantity(data.quantity);
          setTotal(data.total);
          setStateLabel(data.state_label);
          setState(data.state);
          setDt(new Date(data.billing_date));
          setDraft(data.draft);
        })
        .catch((err: any) => {
          window.functions.logout();
        });

      window
        .axios({
          method: "get",
          url:
            window.baseUrl +
            "/comment?type=0&target_id=" +
            props.route.params.id,
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          if (data) {
            console.log(data);
            setComments(data);
            //
            let ur = 0;
            data.forEach((item: any) => {
              if (!item.read_flag) {
                ur++;
              }
            });
            setUnread(ur);
            //
            setSelectedIndex(0);
            setIsLoading(false);
          }
        })
        .catch((err: any) => {
          window.functions.logout();
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const component1 = () => <Text>請求内容</Text>;
  const component2 = () => (
    <Text>
      コメント{comments.length > 0 ? "(" + comments.length + ")" : ""}{" "}
      {unread > 0 && <Badge value={unread} status="error" />}
    </Text>
  );
  const buttons = [{ element: component1 }, { element: component2 }];

  return (
    <View style={styles.container}>
      <View style={{ width: "100%" }}>
        {props.route.params.id > 0 && draft != 1 && (
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: stateColor[state],
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 14 }}>
              {state_label}
            </Text>
          </View>
        )}
        {draft == 1 && (
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: "#ccc",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 14 }}>下書き</Text>
          </View>
        )}
        {props.route.params.id > 0 && draft != 1 && (
          <ButtonGroup
            onPress={(index) => {
              if (index == 1 && unread > 0) window.reloadFlag = true;
              window
                .axios({
                  method: "get",
                  url:
                    window.baseUrl +
                    "/comment?type=0&target_id=" +
                    props.route.params.id +
                    (index == 1 ? "&read=true" : ""),
                  headers: { Authorization: "Bearer " + window.accessToken },
                })
                .then(({ data }: any) => {
                  if (data) {
                    console.log(data);
                    setComments(data);
                    //
                    let ur = 0;
                    data.forEach((item: any) => {
                      if (!item.read_flag) {
                        ur++;
                      }
                    });
                    setUnread(ur);
                    //
                    setSelectedIndex(index);
                  }
                })
                .catch((err: any) => {});
            }}
            selectedIndex={selectedIndex}
            buttons={buttons}
            containerStyle={{ borderWidth: 0, height: 40 }}
            innerBorderStyle={{ width: 0 }}
            selectedButtonStyle={{
              borderBottomWidth: 2,
              borderBottomColor: "rgb(47, 149, 220)",
              backgroundColor: "white",
            }}
          />
        )}
      </View>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
          display: selectedIndex == 0 || selectedIndex == -1 ? "flex" : "none",
        }}
      >
        {props.route.params.id > 0 &&
          (state == 0 ||
            state == 1 ||
            state == 2 ||
            state == 3 ||
            state == 4) &&
          draft != 1 && (
            <View
              style={{
                width: "100%",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <View style={styles.itemViewStyle}>
                <Text style={{}}>請求日</Text>
                <Text style={styles.itemStyle}>{billing_date}</Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>請求先</Text>
                <Text style={styles.itemStyle}>{to_org_name}</Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>請求元</Text>
                <Text style={styles.itemStyle}>{from_org_name}</Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>分類（仕事）</Text>
                <Text style={styles.itemStyle}>{work_type_name}</Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>仕事</Text>
                <Text style={styles.itemStyle}>{work}</Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>単価／時給</Text>
                <Text style={styles.itemStyle}>
                  {numberFormat(Number(unit_price))}
                </Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>数量／時間</Text>
                <Text style={styles.itemStyle}>
                  {numberFormat(Number(quantity))}
                </Text>
              </View>
              <View style={styles.itemViewStyle}>
                <Text style={{}}>合計</Text>
                <Text style={styles.itemStyle}>
                  {numberFormat(Number(total))}
                </Text>
              </View>

              {(state == 0 || state == 3 || state == 4) && (
                <Button
                  style={{ marginTop: 10 }}
                  title="受領する"
                  titleStyle={{ fontSize: 17 }}
                  disabled={false}
                  onPress={() => {
                    if (confirm("受領してもよろしいですか？")) {
                      window
                        .axios({
                          method: "put",
                          url:
                            window.baseUrl +
                            "/receipt/" +
                            props.route.params.id,
                          data: {
                            id: props.route.params.id,
                            state: 1,
                          },
                          headers: {
                            Authorization: "Bearer " + window.accessToken,
                          },
                        })
                        .then((ret: any) => {
                          window.reloadFlag = true;
                          navigation.navigate("ReceiptScreen");
                        })
                        .catch((err: any) => {
                          window.functions.logout();
                        });
                    }
                  }}
                />
              )}
              {(state == 0 || state == 4) && (
                <Button
                  title="保留する"
                  type="outline"
                  titleStyle={{ fontSize: 15 }}
                  buttonStyle={{ height: 30 }}
                  style={{ marginTop: 10 }}
                  onPress={() => {
                    if (confirm("保留してもよろしいですか？")) {
                      window
                        .axios({
                          method: "put",
                          url:
                            window.baseUrl +
                            "/receipt/" +
                            props.route.params.id,
                          data: {
                            id: props.route.params.id,
                            state: 3,
                          },
                          headers: {
                            Authorization: "Bearer " + window.accessToken,
                          },
                        })
                        .then((ret: any) => {
                          window.reloadFlag = true;
                          navigation.navigate("ReceiptScreen");
                        })
                        .catch((err: any) => {
                          window.functions.logout();
                        });
                    }
                  }}
                />
              )}
              {(state == 0 || state == 3 || state == 4) && (
                <Button
                  title="却下する"
                  type="outline"
                  titleStyle={{ fontSize: 15, color: "#f00" }}
                  buttonStyle={{ height: 30, borderColor: "#f00" }}
                  style={{ marginTop: 10 }}
                  onPress={() => {
                    if (confirm("却下してもよろしいですか？")) {
                      window
                        .axios({
                          method: "put",
                          url:
                            window.baseUrl +
                            "/receipt/" +
                            props.route.params.id,
                          data: {
                            id: props.route.params.id,
                            state: 2,
                          },
                          headers: {
                            Authorization: "Bearer " + window.accessToken,
                          },
                        })
                        .then((ret: any) => {
                          window.reloadFlag = true;
                          navigation.navigate("ReceiptScreen");
                        })
                        .catch((err: any) => {
                          window.functions.logout();
                        });
                    }
                  }}
                />
              )}
            </View>
          )}
      </ScrollView>
      {/* コメント画面 */}
      <View
        style={{
          flex: 1,
          width: "100%",
          display: selectedIndex == 1 || selectedIndex == -1 ? "flex" : "none",
        }}
      >
        <View
          style={{ flex: 1, marginBottom: 70 }}
          onLayout={(info) => {
            setListHeight(info.nativeEvent.layout.height);
          }}
        >
          <FlatList
            inverted
            style={{
              width: "100%",
              position: "absolute",
              maxHeight: listHeight,
            }}
            keyExtractor={keyExtractor}
            data={comments}
            renderItem={renderItem}
            ref={(ref) => {
              window.commentList = ref;
            }}
          />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: 70,
            bottom: 0,
            left: 0,
            backgroundColor: "#fff",
            flexDirection: "row",
            borderTopColor: "#eee",
            borderTopWidth: 1,
          }}
        >
          <View
            style={{
              flex: 1,
              height: "100%",
              backgroundColor: "#fff",
              padding: 5,
              paddingLeft: 20,
            }}
          >
            <TextInput
              multiline
              numberOfLines={2}
              style={{
                flex: 1,
                borderWidth: 1,
                borderColor: "#ddd",
                borderRadius: 3,
              }}
              onChangeText={(value) => {
                setComment(value);
              }}
              value={comment}
            />
          </View>
          <View
            style={{
              width: 70,
              height: "100%",
              backgroundColor: "#fff",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              title="送信"
              titleStyle={{ fontSize: 14 }}
              onPress={() => {
                if (comment != "" && comment != null) {
                  window
                    .axios({
                      method: "post",
                      url: window.baseUrl + "/comment",
                      data: {
                        type: 0,
                        target_id: props.route.params.id,
                        comment: comment,
                      },
                      headers: {
                        Authorization: "Bearer " + window.accessToken,
                      },
                    })
                    .then(({ data }: any) => {
                      setComments(data);
                      //
                      let ur = 0;
                      data.forEach((item: any) => {
                        if (!item.read_flag) {
                          ur++;
                        }
                      });
                      setUnread(ur);
                      //
                      setComment("");
                    })
                    .catch((err: any) => {
                      window.functions.logout();
                    });
                }
              }}
            />
          </View>
        </View>
      </View>
      {isLoading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            flex: 1,
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

function renderItem({ item }: any) {
  return (
    <View
      style={{
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
        backgroundColor: item.read_flag == false ? "#ffe4e1" : "#fff",
      }}
    >
      <Text style={{ fontWeight: "bold", marginBottom: 3 }}>
        {moment(item.created_at).format("YYYY-MM-DD HH:mm")}　{item.user_name}
      </Text>
      <Text>{item.comment}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
