import * as React from "react";
import { useState, useEffect } from "react";
import {
  Input,
  Button,
  CheckBox,
  Text,
  Badge,
  ButtonGroup,
} from "react-native-elements";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
//import { FlatList } from "react-native-gesture-handler";
import { navigate } from "@react-navigation/compat/lib/typescript/src/NavigationActions";
import { SwipeListView } from "react-native-swipe-list-view";
import { ScrollView } from "react-native-gesture-handler";
//import { TouchableHighlight } from "react-native-gesture-handler";

import formatNumber from "format-number";
const numberFormat = formatNumber({
  round: 0,
});

export default function TeibanScreen(props: any) {
  const navigation = useNavigation();
  const [teibans, setTeibans] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [last_page, setLastPage] = useState(-1);
  const [type, setType] = useState(0);

  async function loadNext() {
    if (page == last_page) {
      return;
    }

    let params: { page: number; type: number } = {
      page: page + 1,
      type,
    };
    window
      .axios({
        method: "get",
        url: window.baseUrl + "/teiban",
        params,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        if (data.data) {
          let tmp = teibans.slice();
          setTeibans(tmp.concat(data.data));
          setPage(data.current_page);
          setLastPage(data.last_page);
        }
      })
      .catch((err: any) => {
        window.functions.logout();
      });
  }

  async function loadInit(tp: number) {
    let params: { page: number; type: number } = {
      page: 1,
      type: tp,
    };
    window
      .axios({
        method: "get",
        url: window.baseUrl + "/teiban",
        params,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        if (data.data) {
          setTeibans(data.data);
          setPage(data.current_page);
          setLastPage(data.last_page);
        }
      })
      .catch((err: any) => {
        window.functions.logout();
      });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      window.functions.reloadList = () => {
        loadInit(window.currentTeibanType);
      };
      if (window.reloadFlag) {
        window.reloadFlag = false;
        /*
        setTeibans([]);
        setPage(0);
        setLastPage(-1);
        loadNext();
        */
        loadInit(window.currentTeibanType);
      }
    });

    loadNext();
  }, []);

  function changeType(selectedInndex: number) {
    window.currentTeibanType = selectedInndex;
    setType(selectedInndex);
    loadInit(selectedInndex);
  }

  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          padding: 10,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
      >
        <View style={{ flexDirection: "row", height: 40, width: "100%" }}>
          <TouchableOpacity
            onPress={() => {
              changeType(0);
            }}
            style={{
              flex: 1,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
              borderColor: "#3cb371",
              backgroundColor: type == 0 ? "#3cb371" : "#fff",
              borderWidth: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: type == 0 ? "#fff" : "#3cb371",
                fontWeight: "bold",
              }}
            >
              請求
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              changeType(1);
            }}
            style={{
              flex: 1,
              borderTopRightRadius: 20,
              borderBottomRightRadius: 20,
              borderColor: "#3cb371",
              backgroundColor: type == 0 ? "#fff" : "#3cb371",
              borderWidth: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: type == 0 ? "#3cb371" : "#fff",
                fontWeight: "bold",
              }}
            >
              発注
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView style={{ width: "100%", height: 1 }}>
        <SwipeListView
          useFlatList={true}
          disableRightSwipe
          style={{ flex: 1 }}
          keyExtractor={keyExtractor}
          data={teibans}
          renderItem={renderItem}
          onRefresh={() => {
            setIsFetching(true);
          }}
          refreshing={isFetching}
          onEndReached={() => {
            loadNext();
          }}
          onEndReachedThreshold={0.2}
          renderHiddenItem={(rowData, rowMap) => (
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <TouchableOpacity
                style={{
                  width: 70,
                  height: "100%",
                  backgroundColor: "#f00",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={async () => {
                  if (!confirm("削除してもよいですか？")) return;
                  let ret = window.axios({
                    method: "delete",
                    url: window.baseUrl + "/teiban/" + rowData.item.id,
                    headers: { Authorization: "Bearer " + window.accessToken },
                  });
                  //rowMap[rowData.item.id].closeRow();

                  let tmp: any = teibans.slice();
                  for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].id == rowData.item.id) {
                      tmp.splice(i, 1);
                      break;
                    }
                  }
                  setTeibans(tmp);
                }}
              >
                <FontAwesome name="trash" size={24} color="#fff" />
              </TouchableOpacity>
            </View>
          )}
          leftOpenValue={0}
          rightOpenValue={-70}
          onRowOpen={(rowKey, rowMap) => {
            setTimeout(() => {
              if (rowMap[rowKey]) rowMap[rowKey].closeRow();
            }, 2000);
          }}
        />
      </ScrollView>
      <TouchableOpacity
        style={{
          position: "absolute",
          bottom: 60,
          right: 30,
          width: 50,
          height: 50,
          backgroundColor: "#3cb371",
          borderRadius: 25,
          justifyContent: "center",
          alignItems: "center",
        }}
        onPress={() => {
          window.navi.navigate("TeibanDetailScreen", { id: 0, type });
        }}
      >
        <FontAwesome name="plus" size={24} color="#fff" />
      </TouchableOpacity>
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

function renderItem({ item }: any) {
  const stateColor: string[] = ["#a0d8ef", "#9ae69a", "#f19ca7", "#bdafdb"];
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{
        width: "100%",
        height: 70,
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        flexDirection: "row",
        backgroundColor: "#fff",
        paddingLeft: 10,
      }}
      onPress={() => {
        window.navi.navigate("TeibanDetailScreen", {
          id: item.id,
        });
      }}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          width: "*",
        }}
      >
        <View>
          <Text style={{ fontSize: 12, marginBottom: 5 }}>
            {item.to_org_name}
          </Text>
        </View>
        <View style={{}}>
          <Text
            style={{
              width: window.width - 80,
              fontSize: 16,
              fontWeight: "bold",
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.work}
          </Text>
        </View>
        <Text
          style={{
            fontSize: 14,
            paddingBottom: 2,
            color: "#ff0000",
          }}
        >
          {numberFormat(Number(item.total))}
        </Text>
      </View>
      <View
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          width: 10,
          paddingRight: 10,
          flexDirection: "row",
          marginLeft: "auto",
        }}
      >
        <Text style={{ paddingLeft: 3 }}>{">"}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});
