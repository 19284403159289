import * as React from "react";
import { useState, useEffect } from "react";
import { Input, Button, CheckBox, Text, Badge } from "react-native-elements";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
//import { FlatList } from "react-native-gesture-handler";
import { navigate } from "@react-navigation/compat/lib/typescript/src/NavigationActions";
import { SwipeListView } from "react-native-swipe-list-view";
//import { TouchableHighlight } from "react-native-gesture-handler";

import formatNumber from "format-number";
const numberFormat = formatNumber({
  round: 0,
});

export default function InvoiceScreen(props: any) {
  const navigation = useNavigation();
  const [invoices, setInvoices] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [last_page, setLastPage] = useState(-1);
  const [filter, setFilter] = useState(null);

  const [total, setTotal]: any = useState({});

  async function loadNext() {
    if (page == last_page) return;

    let filter = await window.storage
      .load({ key: "InvoiceFilter" })
      .catch((err: any) => null);
    setFilter(filter);

    let params: { page: number; filter?: any } = { page: page + 1 };
    if (filter) {
      params.filter = filter;
    }
    window
      .axios({
        method: "get",
        url: window.baseUrl + "/invoice",
        params,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        if (data.data) {
          let tmp = invoices.slice();
          window.invoices = tmp.concat(data.data);
          setInvoices(window.invoices);
          setPage(data.current_page);
          setLastPage(data.last_page);
        }
      })
      .catch((err: any) => {
        window.functions.logout();
      });
  }

  async function getTotal() {
    let filter = await window.storage
      .load({ key: "InvoiceFilter" })
      .catch((err: any) => null);
    setFilter(filter);

    let params: { filter?: any } = {};
    if (filter) {
      params.filter = filter;
    }
    window
      .axios({
        method: "post",
        url: window.baseUrl + "/invoice/total",
        params,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        setTotal(Object.assign({}, data.total));
      })
      .catch((err: any) => {
        window.functions.logout();
      });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      window.functions.openFilter = () => {
        navigation.navigate("InvoiceFilterScreen", {
          target: "Invoice",
          backScreen: "InvoiceScreen",
        });
      };
      window.functions.reloadList = () => {
        setInvoices([]);
        window.invoices = [];
        setPage(0);
        setLastPage(-1);
        getTotal();
        loadNext();
      };
      if (window.reloadFlag) {
        window.reloadFlag = false;
        setInvoices([]);
        window.invoices = [];
        setPage(0);
        setLastPage(-1);
        getTotal();
        loadNext();
      }
    });

    getTotal();
    loadNext();
  }, []);

  useEffect(() => {}, []);

  async function _update() {
    console.log("invoices:::", invoices);
    if (invoices) {
      let items = invoices.slice();
      let tmp: any = [];
      let p: any = [];
      items.forEach(async (item: any, index) => {
        p.push(
          await window
            .axios({
              method: "get",
              url: window.baseUrl + "/invoice/item?id=" + item.id,
              headers: { Authorization: "Bearer " + window.accessToken },
            })
            .then(({ data }: any) => {
              tmp.push(data);
            })
            .catch((err: any) => {})
        );
      });
      await Promise.all(p);
      setInvoices(tmp);
    }
  }

  return (
    <View style={styles.container}>
      {filter && (
        <View
          style={{
            height: 18,
            backgroundColor: "#008000",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "#fff", fontSize: 14 }}>フィルタ中</Text>
        </View>
      )}
      <View
        style={{
          width: "100%",
          flexDirection: "column",
          padding: 10,
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              flex: 1,
              marginRight: 10,
              flexDirection: "row",
              backgroundColor: "#e1fae1",
            }}
          >
            <Text style={{ fontSize: 11, color: "#333", width: 120 }}>
              定番受領：
            </Text>
            <Text
              style={{
                fontSize: 15,
                textAlign: "right",
                color: "#ff0000",
                width: "100%",
              }}
            >
              {numberFormat(Number(total.teiban_jyuryou))}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
              flexDirection: "row",
              backgroundColor: "#e1fae1",
            }}
          >
            <Text style={{ fontSize: 11, color: "#333", width: 120 }}>
              定番確認中：
            </Text>
            <Text
              style={{
                fontSize: 15,
                textAlign: "right",
                color: "#ff8c00",
                width: "100%",
              }}
            >
              {numberFormat(Number(total.teiban_kakunin))}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, paddingRight: 10, flexDirection: "row" }}>
            <Text style={{ fontSize: 11, color: "#333", width: 120 }}>
              一般受領：
            </Text>
            <Text
              style={{
                fontSize: 15,
                textAlign: "right",
                color: "#ff0000",
                width: "100%",
              }}
            >
              {numberFormat(Number(total.normal_jyuryou))}
            </Text>
          </View>
          <View style={{ flex: 1, paddingLeft: 10, flexDirection: "row" }}>
            <Text style={{ fontSize: 11, color: "#333", width: 120 }}>
              一般確認中：
            </Text>
            <Text
              style={{
                fontSize: 15,
                textAlign: "right",
                color: "#ff8c00",
                width: "100%",
              }}
            >
              {numberFormat(Number(total.normal_kakunin))}
            </Text>
          </View>
        </View>
      </View>
      <SwipeListView
        useFlatList={true}
        disableRightSwipe
        style={{ width: "100%" }}
        keyExtractor={keyExtractor}
        data={invoices}
        renderItem={renderItem}
        onRefresh={() => {
          setIsFetching(true);
        }}
        refreshing={isFetching}
        onEndReached={() => {
          loadNext();
        }}
        onEndReachedThreshold={0.2}
        renderHiddenItem={(rowData, rowMap) => (
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              style={{
                width: 70,
                height: "100%",
                backgroundColor: "#f00",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={async () => {
                if (!confirm("削除してもよいですか？")) return;
                let ret = window.axios({
                  method: "delete",
                  url: window.baseUrl + "/invoice/" + rowData.item.id,
                  headers: { Authorization: "Bearer " + window.accessToken },
                });
                //rowMap[rowData.item.id].closeRow();

                let tmp: any = invoices.slice();
                for (let i = 0; i < tmp.length; i++) {
                  if (tmp[i].id == rowData.item.id) {
                    tmp.splice(i, 1);
                    break;
                  }
                }
                setInvoices(tmp);
                window.invoices = tmp;
              }}
            >
              <FontAwesome name="trash" size={24} color="#fff" />
            </TouchableOpacity>
          </View>
        )}
        leftOpenValue={0}
        rightOpenValue={-70}
        onRowOpen={(rowKey, rowMap) => {
          setTimeout(() => {
            if (rowMap[rowKey]) rowMap[rowKey].closeRow();
          }, 2000);
        }}
      />
      <TouchableOpacity
        style={{
          position: "absolute",
          bottom: 30,
          right: 100,
          width: 50,
          height: 50,
          backgroundColor: "#3cb371",
          borderRadius: 25,
          justifyContent: "center",
          alignItems: "center",
        }}
        onPress={() => {
          window.navi.navigate("InvoiceTeibanSelectScreen", { type: 0 });
        }}
      >
        <Text style={{ color: "#fff", fontSize: 14 }}>定番</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          position: "absolute",
          bottom: 30,
          right: 30,
          width: 50,
          height: 50,
          backgroundColor: "#2f95dc",
          borderRadius: 25,
          justifyContent: "center",
          alignItems: "center",
        }}
        onPress={() => {
          window.navi.navigate("InvoiceDetailScreen", { id: 0 });
        }}
      >
        <FontAwesome name="plus" size={24} color="#fff" />
      </TouchableOpacity>
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

function renderItem({ item }: any) {
  const stateColor: string[] = [
    "#a0d8ef",
    "#9ae69a",
    "#f19ca7",
    "#bdafdb",
    "#f4a460",
  ];
  return (
    <TouchableOpacity
      activeOpacity={1}
      style={{
        width: "100%",
        height: 70,
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        flexDirection: "row",
        backgroundColor: item.teiban_id > 0 ? "#e1fae1" : "#fff",
      }}
      onPress={() => {
        window.navi.navigate("InvoiceDetailScreen", { id: item.id });
      }}
    >
      <View
        style={{ justifyContent: "center", alignItems: "center", width: 70 }}
      >
        {item.draft != 1 && (
          <View
            style={{
              backgroundColor: stateColor[item.state],
              padding: 2,
              width: 50,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                textAlign: "center",
              }}
            >
              {item.state_label}
            </Text>
          </View>
        )}
        {item.draft == 1 && (
          <View
            style={{
              backgroundColor: "#ccc",
              padding: 2,
              width: 50,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                textAlign: "center",
              }}
            >
              下書き
            </Text>
          </View>
        )}
        {item.commented > 0 && (
          <Text style={{ color: "#c00", fontSize: 9, paddingTop: 3 }}>
            コメント済
          </Text>
        )}
      </View>
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          width: "*",
        }}
      >
        <View>
          <Text style={{ fontSize: 12, marginBottom: 5 }}>
            {item.billing_date}
            {"　"}
            {item.to_org_name_s}
          </Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {item.teiban_id > 0 && (
            <View
              style={{
                backgroundColor: "#60B077",
                padding: 1,
                width: 40,
                marginRight: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 11,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                定番
              </Text>
            </View>
          )}
          <Text
            style={{
              width: window.width - 80 - (item.teiban_id > 0 ? 45 : 0),
              fontSize: 16,
              fontWeight: "bold",
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.work}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 14,
              paddingBottom: 2,
              color: item.state == 1 ? "#ff0000" : "#ff8c00",
            }}
          >
            {numberFormat(Number(item.total))}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingBottom: 2,
              color: "#333",
            }}
          >
            {"　請求元："}
            {item.from_org_name_s}
          </Text>
        </View>
      </View>
      <View
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          width: 10,
          paddingRight: 10,
          flexDirection: "row",
          marginLeft: "auto",
        }}
      >
        {item.unread > 0 && <Badge value={item.unread} status="error" />}
        <Text style={{ paddingLeft: 3 }}>{">"}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  totalLabel: {
    fontSize: 11,
    color: "#aaa",
  },
});
