import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  ScrollView,
} from "react-native";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import styled from "styled-components";

import { useNavigation } from "@react-navigation/native";

const Styles = styled.div`
  display: block;
  max-width: 100%;

  .info {
    font-size: 12px;
  }

  .ym {
    font-wait: bold;
  }

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 5px;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    border-collapse: collapse;
    border-color: "#ccc";

    tr {
      :last-child {
        td {
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.2rem;
      border: 1px solid black;
      font-size: 12px;
      border-color: #ccc;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
      }
    }
  }
`;

export default function HomeScreen() {
  const navigation = useNavigation();

  const [filter, setFilter] = useState(null);
  const [trs, setTrs] = useState([]);
  const [total, setTotal] = useState(0);
  const [to_org, setToOrg] = useState("");
  const [from_org, setFromOrg] = useState("");
  const [ym_disp, setYmDisp] = useState("");
  const [goal, setGoal] = useState(0);
  const [sagaku, setSagaku] = useState(0);

  async function _getTotal() {
    let filter = await window.storage
      .load({ key: "HomeFilter" })
      .catch((err: any) => null);
    setFilter(filter);

    let params: { filter?: any } = {};
    if (filter) {
      params.filter = filter;
    }
    //alert(window.accessToken);
    window
      .axios({
        method: "post",
        url: window.baseUrl + "/home/totalup",
        params,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        console.log(data);
        setTotal(data.total);
        setGoal(data.goal);
        setSagaku(data.sagaku);

        let trs: any = [];
        if (data.list) {
          Object.keys(data.list).forEach((key: any) => {
            let val = data.list[key];
            trs.push(
              <tr
                key={key}
                style={{
                  backgroundColor: val.today == 1 ? "#1e90ff" : "#fff",
                  color: val.today == 1 ? "#fff" : "#000",
                  fontWeight: val.today == 1 ? "bold" : "normal",
                }}
              >
                <td style={{ fontSize: 10 }}>{val.billing_date}</td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(val.sum_jyuryou / 1000).toLocaleString()}
                </td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(val.total / 1000).toLocaleString()}
                </td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(val.total_k / 1000).toLocaleString()}
                </td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(val.total_tj / 1000).toLocaleString()}
                </td>
                <td style={{ textAlign: "right" }}>
                  {Math.round(val.total_tk / 1000).toLocaleString()}
                </td>
              </tr>
            );
          });
          setTrs(trs);
          setYmDisp(data.ym);
          setToOrg(data.to_org);
          setFromOrg(data.from_org);
        }
      })
      .catch((err: any) => {
        console.log(err);
        window.functions.logout();
      });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      window.functions.openFilter = () => {
        navigation.navigate("HomeFilterScreen", {
          target: "Home",
          backScreen: "HomeScreen",
        });
      };
      window.functions.openSalesTarget = () => {
        navigation.navigate("SalesTargetScreen", {
          backScreen: "HomeScreen",
        });
      };
      _getTotal();
    });
  }, []);

  return (
    <View style={styles.container}>
      {/*<View style={{ width: "100%", backgroundColor: "#ddd", padding: 10 }}>
        <View>
          <Text>本日の売上</Text>
        </View>
        <View>
          <Text style={{ fontSize: 20, textAlign: "right" }}>¥100,000</Text>
        </View>
      </View>*/}
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <Styles>
          <div
            className="info"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 5,
              fontSize: 14,
            }}
          >
            {ym_disp}
          </div>
          <table>
            <tbody>
              <tr>
                <td className="info">
                  依頼主: <span style={{ fontSize: 14 }}>{to_org}</span>
                </td>
                <td className="info">
                  仕事側: <span style={{ fontSize: 14 }}>{from_org}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="tableWrap">
            <table
              style={{
                fontSize: 12,
              }}
            >
              <tbody>
                <tr>
                  <td style={{ fontSize: 10 }}>当月目標</td>
                  <td
                    style={{
                      textAlign: "right",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {(goal * 10).toLocaleString()}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      fontSize: 15,
                      fontWeight: "bold",
                      color: sagaku < 0 ? "#f00" : "#000",
                    }}
                  >
                    {sagaku >= 0
                      ? Math.round(sagaku / 1000).toLocaleString()
                      : "△" +
                        Math.round((sagaku * -1) / 1000).toLocaleString()}
                  </td>
                  <td style={{ fontSize: 10 }}>受領合計</td>
                  <td
                    colSpan={2}
                    style={{
                      textAlign: "right",
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {Math.round(total / 1000).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "center" }} colSpan={3}>
                    一般
                  </td>
                  <td style={{ textAlign: "center" }} colSpan={2}>
                    定番
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>日付</td>
                  <td style={{ textAlign: "center" }}>当日受領</td>
                  <td style={{ textAlign: "center" }}>累計受領</td>
                  <td style={{ textAlign: "center" }}>確認中</td>
                  <td style={{ textAlign: "center" }}>受領</td>
                  <td style={{ textAlign: "center" }}>確認中</td>
                </tr>
                {trs}
              </tbody>
            </table>
          </div>
        </Styles>
        {/*<Button
          title="ログアウト"
          onPress={() => {
            window.functions.logout();
          }}
        />*/}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  td: {
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#ccc",
  },
});
