import { Ionicons } from "@expo/vector-icons";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import LoginScreen from "../screens/LoginScreen";
import { LoginParamList } from "../types";

const LoginStack = createStackNavigator<LoginParamList>();

export default function LoginNavigator(props: any) {
  return (
    <LoginStack.Navigator>
      <LoginStack.Screen
        name="LoginScreen"
        options={{
          headerTitle: "ログイン",
          title: "ログイン",
          headerShown: false,
        }}
      >
        {() => <LoginScreen functions={props.functions} />}
      </LoginStack.Screen>
    </LoginStack.Navigator>
  );
}
