import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  ScrollView,
  TextInput,
  MaskedViewComponent,
} from "react-native";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import styled from "styled-components";

import { useNavigation } from "@react-navigation/native";

const moment = require("moment");

const Styles = styled.div`
  display: block;
  max-width: 100%;

  .info {
    font-size: 12px;
  }

  .ym {
    font-wait: bold;
  }

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 5px;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    border-collapse: collapse;
    border-color: "#ccc";

    tr {
      :last-child {
        td {
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.2rem;
      border: 1px solid black;
      font-size: 12px;
      border-color: #ccc;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
      }
    }
  }
`;

export default function SalesTargetScreen() {
  const navigation = useNavigation();

  const [orgs, setOrgs]: any = useState([]);
  const [goals, setGoals]: any = useState({});
  const [errs, setErrs]: any = useState({});

  const [yyyy, setYYYY]: any = useState([]);
  const [mm, setMM]: any = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

  const [selectY, setSelectY] = useState(moment().format("YYYY"));
  const [selectM, setSelectM] = useState(moment().format("M"));

  async function _getOrgs(y = selectY, m = selectM) {
    let { data: org_list } = await window.axios({
      method: "post",
      url: window.baseUrl + "/organisation/goallist",
      headers: { Authorization: "Bearer " + window.accessToken },
      data: {
        y,
        m,
      },
    });

    console.log("org_list:::", org_list);

    setOrgs(org_list);
    if (org_list) {
      let gl: any = {};
      Object.keys(org_list).forEach((key: any) => {
        let val = org_list[key];
        gl["gl_" + val.id] = val.goal;
      });
      setGoals(gl);
    }
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      _getOrgs();
    });
  }, []);

  let trs: any = [];
  Object.keys(orgs).forEach((key: any) => {
    let val: any = orgs[key];
    trs.push(
      <tr key={val.id}>
        <td>{val.label_name}</td>
        <td>
          <TextInput
            style={{
              width: "100%",
              borderColor: "#eee",
              borderWidth: 1,
            }}
            value={"gl_" + val.id in goals ? goals["gl_" + val.id] : 0}
            keyboardType="number-pad"
            onChangeText={(v) => {
              let gl: any = Object.assign({}, goals);
              gl["gl_" + val.id] = v;
              setGoals(gl);
            }}
          />
          <span style={{ color: "#c00" }}>
            {"gl_" + val.id in errs ? errs["gl_" + val.id] : ""}
          </span>
        </td>
      </tr>
    );
  });

  let ys: any = [];
  let start_y = moment().format("Y") - 3;
  for (let i = 0; i < 7; i++) {
    ys.push(
      <option style={{ fontSize: 18 }} value={start_y + i}>
        {start_y + i}年
      </option>
    );
  }

  let ms: any = [];
  mm.forEach((val: any) => {
    ms.push(
      <option style={{ fontSize: 18 }} value={val}>
        {val}月
      </option>
    );
  });

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <Styles>
          <div className="tableWrap">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <div style={{ width: "100%" }}>
                <select
                  value={selectY}
                  style={{ width: "100%", fontSize: 18 }}
                  onChange={(e) => {
                    //alert(e.target.value);
                    setSelectY(e.target.value);
                    _getOrgs(e.target.value, selectM);
                  }}
                >
                  {ys}
                </select>
              </div>
              <div style={{ width: "100%" }}>
                <select
                  value={selectM}
                  style={{ width: "100%", fontSize: 18 }}
                  onChange={(e) => {
                    //alert(e.target.value);
                    setSelectM(e.target.value);
                    _getOrgs(selectY, e.target.value);
                  }}
                >
                  {ms}
                </select>
              </div>
            </div>
            <table
              style={{
                fontSize: 12,
              }}
            >
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>部門</td>
                  <td style={{ textAlign: "center" }}>当月目標(万単位)</td>
                </tr>
                {trs}
              </tbody>
            </table>
          </div>
          <div>
            <Button
              style={{ marginTop: 30, marginBottom: 30 }}
              title="設定する"
              onPress={() => {
                console.log(goals);
                setErrs({});
                let flag = true;
                let err: any = {};
                Object.keys(goals).forEach((key: any) => {
                  let val = goals[key];
                  if (val === "") {
                    err[key] = "入力してください";
                    flag = false;
                  } else if (!isFinite(parseInt(val))) {
                    err[key] = "数値で入力してください";
                    flag = false;
                  }
                  setErrs(err);
                });
                if (!flag) {
                  alert("目標値をご確認ください");
                  return;
                }

                window
                  .axios({
                    method: "post",
                    url: window.baseUrl + "/organisation/goals",
                    data: {
                      goals,
                      y: selectY,
                      m: selectM,
                    },
                    headers: { Authorization: "Bearer " + window.accessToken },
                  })
                  .then((res: any) => {
                    const { data } = res;
                    if (!data.result) {
                      alert("設定に失敗しました");
                    } else {
                      //成功
                      navigation.navigate("HomeScreen");
                    }
                  })
                  .catch((err: any) => {});
              }}
            />
          </div>
        </Styles>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  td: {
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#ccc",
  },
});
