import * as React from "react";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { Input, Button, CheckBox, Text } from "react-native-elements";

export default function LoginScreen(props: any) {
  const { navigation, functions } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [errstr, setErrstr] = useState("");

  return (
    <View style={styles.container}>
      <View style={{ width: "100%", flex: 1 }}>
        <View
          style={{
            width: "100%",
            height: 250,
            backgroundColor: "#eee",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <Text>ロゴなど</Text>
        </View>

        <Input
          placeholder="メールアドレス"
          leftIcon={
            <Icon name="envelope" size={22} color="rgb(32, 137, 220)" />
          }
          style={{ padding: 5 }}
          value={email}
          onChangeText={(value) => setEmail(value)}
        />
        <Input
          placeholder="パスワード"
          leftIcon={<Icon name="lock" size={30} color="rgb(32, 137, 220)" />}
          style={{ padding: 5 }}
          secureTextEntry={true}
          value={password}
          onChangeText={(value) => setPassword(value)}
        />

        {/*<View style={{ marginTop: 20 }}>
          <CheckBox
            center
            title="ログインを維持する"
            checked={remember}
            onPress={() => {
              setRemember(!remember);
            }}
          />
          </View>*/}

        {errstr != "" && (
          <View>
            <Text style={{ color: "#c00", textAlign: "center" }}>{errstr}</Text>
          </View>
        )}

        <Button
          style={{ marginTop: 30 }}
          title="ログインする"
          onPress={() => {
            setErrstr("");
            window
              .axios({
                method: "post",
                url: window.baseUrl + "/login",
                data: {
                  email,
                  password,
                },
                headers: {},
              })
              .then((res: any) => {
                const { data } = res;
                if (!data.result) {
                  setErrstr("認証に失敗しました");
                } else {
                  window.storage.save({
                    key: "accessToken",
                    data: data.token,
                  });
                  window.functions.setIsAuth(true);
                }
              })
              .catch((err: any) => {});
          }}
        />
        <View style={{ flexGrow: 1, minHeight: 100 }}></View>
        <View style={{ height: 20 }}>
          <Text style={{ fontSize: 12, textAlign: "center" }}>
            ©️ 2020 Foster Co. Ltd. All rights reserved.
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 10,
    backgroundColor: "#fff",
  },
});
