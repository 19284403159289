import { Ionicons } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  CardStyleInterpolators,
  createStackNavigator,
  HeaderBackButton,
} from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { TouchableOpacity, View, Text, SafeAreaView } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

import HomeScreen from "../screens/HomeScreen";

import InvoiceScreen from "../screens/InvoiceScreen";
import InvoiceDetailScreen from "../screens/InvoiceDetailScreen";
import SelectOrganisationScreen from "../screens/SelectOrganisationScreen";
import SelectWorkTypeScreen from "../screens/SelectWorkTypeScreen";

import OrderScreen from "../screens/OrderScreen";
import OrderDetailScreen from "../screens/OrderDetailScreen";
//import SelectOrderOrganisationScreen from "../screens/SelectOrderOrganisationScreen";
//import SelectOrderWorkTypeScreen from "../screens/SelectOrderWorkTypeScreen";

import ReceiptScreen from "../screens/ReceiptScreen";
import ReceiptDetailScreen from "../screens/ReceiptDetailScreen";

import WorkScreen from "../screens/WorkScreen";
import WorkDetailScreen from "../screens/WorkDetailScreen";

import TeibanScreen from "../screens/TeibanScreen";
import TeibanDetailScreen from "../screens/TeibanDetailScreen";
import FilterScreen from "../screens/FilterScreen";
import HomeFilterScreen from "../screens/HomeFilterScreen";
import TeibanFilterScreen from "../screens/TeibanFilterScreen";

import TeibanSelectScreen from "../screens/TeibanSelectScreen";

import AccountScreen from "../screens/AccountScreen";

import {
  BottomTabParamList,
  HomeTabParamList,
  InvoiceTabParamList,
  OrderTabParamList,
  ReceiptTabParamList,
  WorkTabParamList,
  SettingParamList,
  AccountParamList,
} from "../types";
import SalesTargetScreen from "../screens/SalesTargetScreen";

const Drawer = createDrawerNavigator();
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const navigation: any = useNavigation();
  window.navi = navigation;
  return (
    <Drawer.Navigator initialRouteName="Drawer">
      <Drawer.Screen
        name="Drawer"
        component={BottomTabComponent}
        options={{ title: "ホーム" }}
      />
      <Drawer.Screen
        name="Teiban"
        component={SettingNavigator}
        options={{ title: "定番編集" }}
      />
      <Drawer.Screen
        name="Logout"
        component={AccountNavigator}
        options={{ title: "アカウント" }}
      />
    </Drawer.Navigator>
  );
}

function BottomTabComponent() {
  const colorScheme = useColorScheme();
  const navigation: any = useNavigation();

  return (
    <BottomTab.Navigator
      initialRouteName="Home" //Home
      tabBarOptions={{
        activeTintColor: Colors[colorScheme].tint,
        style: { height: 60 },
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeTabNavigator}
        options={{
          title: "ホーム",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="home"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Invoice"
        component={InvoiceTabNavigator}
        options={{
          title: "請求",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="money"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
          //tabBarBadge: 3,
        }}
      />
      <BottomTab.Screen
        name="Receipt"
        component={ReceiptTabNavigator}
        options={{
          title: "受領",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="check-square-o"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Order"
        component={OrderTabNavigator}
        options={{
          title: "発注",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="wpforms"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Work"
        component={WorkTabNavigator}
        options={{
          title: "受注→請求",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              name="gift"
              color={
                focused
                  ? Colors[colorScheme].tabIconSelected
                  : Colors[colorScheme].tabIconDefault
              }
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

//ヘッダーカスタム
function HeaderLeft(props: any) {
  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          window.functions.openDrawer();
        }}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <FontAwesome name="navicon" size={28} color="#aaa" {...props} />
      </TouchableOpacity>
    </View>
  );
}

function HeaderFilter(props: any) {
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        onPress={() => {
          window.functions.reloadList();
        }}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <FontAwesome name="repeat" size={28} color="#aaa" {...props} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          window.functions.openFilter();
        }}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <FontAwesome name="filter" size={28} color="#aaa" {...props} />
      </TouchableOpacity>
    </View>
  );
}

function HeaderHome(props: any) {
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        onPress={() => {
          window.functions.openSalesTarget();
        }}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <Text style={{ color: "rgb(47, 149, 220)" }}>目標設定</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          window.functions.openFilter();
        }}
        style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 15 }}
      >
        <Text style={{ color: "rgb(47, 149, 220)" }}>対象選択</Text>
      </TouchableOpacity>
    </View>
  );
}

function HeaderTeibanSelect(props: any) {
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        onPress={() => {
          window.functions.teibanAtOnce();
        }}
        style={{ paddingLeft: 10, paddingRight: 10, marginTop: 3 }}
      >
        <Text style={{ fontSize: 16, color: "rgb(32, 137, 220)" }}>
          一括登録
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          window.functions.openFilter();
        }}
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <FontAwesome name="filter" size={28} color="#aaa" {...props} />
      </TouchableOpacity>
    </View>
  );
}

function HeaderOrgWorkSelect(props: any) {
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        onPress={() => {
          window.functions.unset();
        }}
        style={{ paddingLeft: 10, paddingRight: 10, marginTop: 3 }}
      >
        <Text style={{ fontSize: 16, color: "#c00" }}>未選択にする</Text>
      </TouchableOpacity>
    </View>
  );
}

//ホーム
const HomeTabStack = createStackNavigator<HomeTabParamList>();
function HomeTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();
  return (
    <HomeTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <HomeTabStack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          headerTitle: "ホーム",
          headerLeft: HeaderLeft,
          headerRight: HeaderHome,
        }}
      />
      <HomeTabStack.Screen
        name="HomeFilterScreen"
        component={HomeFilterScreen}
        options={{
          headerTitle: "対象選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("HomeScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <HomeTabStack.Screen
        name="SelectHomeOrganisationScreen"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("HomeScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <HomeTabStack.Screen
        name="SalesTargetScreen"
        component={SalesTargetScreen}
        options={{
          headerTitle: "目標設定",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("HomeScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
    </HomeTabStack.Navigator>
  );
}

//請求一覧
const InvoiceTabStack = createStackNavigator<InvoiceTabParamList>();
function InvoiceTabNavigator() {
  const navigation: any = useNavigation();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  window.functions.openDrawer = () => navigation.openDrawer();
  return (
    <InvoiceTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <InvoiceTabStack.Screen
        name="InvoiceScreen"
        component={InvoiceScreen}
        options={{
          headerTitle: "請求",
          headerLeft: HeaderLeft,
          headerRight: HeaderFilter,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="InvoiceDetailScreen"
        component={InvoiceDetailScreen}
        options={{
          headerTitle: "請求書",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                if (props.label == "定番選択") {
                  window.navi.navigate("InvoiceTeibanSelectScreen");
                } else {
                  window.navi.navigate("InvoiceScreen");
                }
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="SelectOrganisationScreen"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceDetailScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="SelectWorkTypeScreen"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceDetailScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="InvoiceFilterScreen"
        component={FilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="SelectInvoiceOrganisationScreen_filter"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="SelectInvoiceWorkTypeScreen_filter"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="InvoiceTeibanSelectScreen"
        component={TeibanSelectScreen}
        options={{
          headerTitle: "定番選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceScreen");
              }}
            />
          ),
          headerRight: HeaderTeibanSelect,
          animationEnabled: true,
        }}
      />

      <InvoiceTabStack.Screen
        name="InvoiceTeibanFilterScreen"
        component={TeibanFilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceTeibanSelectScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="InvoiceTeibanOrganisationScreen_filter"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceTeibanSelectScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <InvoiceTabStack.Screen
        name="InvoiceTeibanWorkTypeScreen_filter"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("InvoiceTeibanSelectScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
    </InvoiceTabStack.Navigator>
  );
}

//発注一覧
const OrderTabStack = createStackNavigator<OrderTabParamList>();
function OrderTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <OrderTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <OrderTabStack.Screen
        name="OrderScreen"
        component={OrderScreen}
        options={{
          headerTitle: "発注",
          headerLeft: HeaderLeft,
          headerRight: HeaderFilter,
        }}
      />
      <OrderTabStack.Screen
        name="OrderDetailScreen"
        component={OrderDetailScreen}
        options={{
          headerTitle: "発注書",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderScreen");
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="SelectOrderOrganisationScreen"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderDetailScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="SelectOrderWorkTypeScreen"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderDetailScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="OrderFilterScreen"
        component={FilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="SelectOrderOrganisationScreen_filter"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="SelectOrderWorkTypeScreen_filter"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="OrderTeibanSelectScreen"
        component={TeibanSelectScreen}
        options={{
          headerTitle: "定番選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderScreen");
              }}
            />
          ),
          headerRight: HeaderTeibanSelect,
          animationEnabled: true,
        }}
      />

      <OrderTabStack.Screen
        name="OrderTeibanFilterScreen"
        component={TeibanFilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderTeibanSelectScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="OrderTeibanOrganisationScreen_filter"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderTeibanSelectScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <OrderTabStack.Screen
        name="OrderTeibanWorkTypeScreen_filter"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("OrderTeibanSelectScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
    </OrderTabStack.Navigator>
  );
}

//受領一覧
const ReceiptTabStack = createStackNavigator<ReceiptTabParamList>();
function ReceiptTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <ReceiptTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <ReceiptTabStack.Screen
        name="ReceiptScreen"
        component={ReceiptScreen}
        options={{
          headerTitle: "受領",
          headerLeft: HeaderLeft,
          headerRight: HeaderFilter,
        }}
      />
      <ReceiptTabStack.Screen
        name="ReceiptDetailScreen"
        component={ReceiptDetailScreen}
        options={{
          headerTitle: "受領処理：請求",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("ReceiptScreen");
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name="ReceiptFilterScreen"
        component={FilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("ReceiptScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name="SelectReceiptOrganisationScreen_filter"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("ReceiptFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <ReceiptTabStack.Screen
        name="SelectReceiptWorkTypeScreen_filter"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("ReceiptFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
    </ReceiptTabStack.Navigator>
  );
}

//依頼一覧
const WorkTabStack = createStackNavigator<WorkTabParamList>();
function WorkTabNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e: any) => {
      window.reloadFlag = true;
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <WorkTabStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <WorkTabStack.Screen
        name="WorkScreen"
        component={WorkScreen}
        options={{
          headerTitle: "受注→請求",
          headerLeft: HeaderLeft,
          headerRight: HeaderFilter,
        }}
      />
      <WorkTabStack.Screen
        name="WorkDetailScreen"
        component={WorkDetailScreen}
        options={{
          headerTitle: "受領処理：発注",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("WorkScreen");
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <WorkTabStack.Screen
        name="WorkFilterScreen"
        component={FilterScreen}
        options={{
          headerTitle: "フィルター",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("WorkScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <WorkTabStack.Screen
        name="SelectWorkOrganisationScreen_filter"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("WorkFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
      <WorkTabStack.Screen
        name="SelectWorkWorkTypeScreen_filter"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("WorkFilterScreen");
              }}
            />
          ),
          headerRight: HeaderOrgWorkSelect,
          animationEnabled: true,
        }}
      />
    </WorkTabStack.Navigator>
  );
}

//設定スタック
const SettingStack = createStackNavigator<SettingParamList>();
function SettingNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();
  return (
    <SettingStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <SettingStack.Screen
        name="TeibanScreen"
        component={TeibanScreen}
        options={{
          headerTitle: "定番編集",
          headerLeft: HeaderLeft,
          headerRight: () => (
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  window.functions.reloadList();
                }}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <FontAwesome name="repeat" size={28} color="#aaa" />
              </TouchableOpacity>
            </View>
          ),
        }}
      />

      <SettingStack.Screen
        name="TeibanDetailScreen"
        component={TeibanDetailScreen}
        options={{
          headerTitle: "定番",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("TeibanScreen");
              }}
            />
          ),
          //headerRight: null,
          animationEnabled: true,
        }}
      />
      <SettingStack.Screen
        name="SelectTeibanOrganisationScreen"
        component={SelectOrganisationScreen}
        options={{
          headerTitle: "組織選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("TeibanDetailScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
      <SettingStack.Screen
        name="SelectTeibanWorkTypeScreen"
        component={SelectWorkTypeScreen}
        options={{
          headerTitle: "仕事分類選択",
          headerLeft: (props) => (
            <HeaderBackButton
              {...props}
              onPress={() => {
                window.navi.navigate("TeibanDetailScreen");
              }}
            />
          ),
          animationEnabled: true,
        }}
      />
    </SettingStack.Navigator>
  );
}

//アカウント画面（現在はログアウトするための画面）
const AccountStack = createStackNavigator<AccountParamList>();
function AccountNavigator() {
  const navigation: any = useNavigation();
  window.functions.openDrawer = () => navigation.openDrawer();
  return (
    <AccountStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <AccountStack.Screen
        name="AccountScreen"
        component={AccountScreen}
        options={{
          headerTitle: "アカウント",
          headerLeft: HeaderLeft,
          headerRight: () => (
            <View style={{ flexDirection: "row" }}>
            </View>
          ),
        }}
      />
    </AccountStack.Navigator>
  );
}