import * as React from "react";
const DatePicker = require("react-mobile-datepicker");
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  TextInput,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import {
  Input,
  Button,
  CheckBox,
  ButtonGroup,
  Badge,
} from "react-native-elements";
import Select from "react-select";

const moment = require("moment");
import formatNumber from "format-number";
import { block } from "react-native-reanimated";
import { Zocial } from "@expo/vector-icons";
const sprintf = require("sprintf-js").sprintf;

const numberFormat = formatNumber({
  round: 0,
});

export default function TeibanFilterScreen(props: any) {
  const { navigation, functions } = props;

  const [to_org, setToOrg] = useState(0);
  const [from_org, setFromOrg] = useState(0);
  const [to_org_name, setToOrgName] = useState("未選択");
  const [from_org_name, setFromOrgName] = useState("未選択");
  const [work_type_id, setWorkTypeId] = useState(0);
  const [work_type_name, setWorkTypeName] = useState("未選択");

  const [isLoading, setIsLoading] = useState(false);

  async function loadFilter() {
    let filter = await window.storage
      .load({ key: "Teiban" + props.route.params.target + "Filter" })
      .catch((err: any) => null);
    if (filter) {
      setToOrg(filter.to_org);
      setFromOrg(filter.from_org);
      setToOrgName(filter.to_org_name);
      setFromOrgName(filter.from_org_name);
      setWorkTypeId(filter.work_type_id);
      setWorkTypeName(filter.work_type_name);
    }
  }

  useEffect(() => {
    loadFilter();
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.itemViewStyle}>
            <Text style={{}}>仕事側（請求元-受取側）</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate(
                  props.route.params.target + "TeibanOrganisationScreen_filter",
                  {
                    id: from_org,
                    setOrg: setFromOrg,
                    setOrgName: setFromOrgName,
                    backScreen:
                      props.route.params.target + "TeibanFilterScreen",
                    type: props.route.params.target == "Invoice" ? 0 : 1,
                    mode: "from_org",
                  }
                );
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {from_org_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
          </View>

          <View style={styles.itemViewStyle}>
            <Text style={{}}>依頼主（請求先-支払側）</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate(
                  props.route.params.target + "TeibanOrganisationScreen_filter",
                  {
                    id: to_org,
                    setOrg: setToOrg,
                    setOrgName: setToOrgName,
                    backScreen:
                      props.route.params.target + "TeibanFilterScreen",
                    type: props.route.params.target == "Invoice" ? 0 : 1,
                    mode: "to_org",
                  }
                );
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {to_org_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
          </View>

          <View style={styles.itemViewStyle}>
            <Text style={{}}>分類（仕事）</Text>
            <TouchableOpacity
              onPress={() => {
                window.navi.navigate(
                  props.route.params.target + "TeibanWorkTypeScreen_filter",
                  {
                    id: work_type_id,
                    setWorkTypeId,
                    setWorkTypeName,
                    backScreen:
                      props.route.params.target + "TeibanFilterScreen",
                  }
                );
              }}
            >
              <Text style={styles.itemStyleEdit}>
                {work_type_name}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
            </TouchableOpacity>
          </View>

          <Button
            title="フィルター実行"
            titleStyle={{ fontSize: 17 }}
            style={{ marginTop: 20 }}
            onPress={() => {
              let data = {
                to_org,
                from_org,
                to_org_name,
                from_org_name,
                work_type_id,
                work_type_name,
              };
              window.storage.save({
                key: "Teiban" + props.route.params.target + "Filter",
                data,
              });
              window.reloadFlag = true;
              navigation.navigate(props.route.params.backScreen);
            }}
          />
          <Button
            title="フィルター解除"
            type="outline"
            titleStyle={{ fontSize: 15 }}
            buttonStyle={{ height: 30 }}
            style={{ marginTop: 10 }}
            onPress={() => {
              window.storage.remove({
                key: "Teiban" + props.route.params.target + "Filter",
              });
              window.reloadFlag = true;
              navigation.navigate(props.route.params.backScreen);
            }}
          />
        </View>
      </ScrollView>

      {isLoading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            flex: 1,
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
