import * as React from "react";
import { useState, useEffect } from "react";
import { Input, Button, CheckBox, Text, Badge } from "react-native-elements";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
//import { FlatList } from "react-native-gesture-handler";
import { navigate } from "@react-navigation/compat/lib/typescript/src/NavigationActions";
import { SwipeListView } from "react-native-swipe-list-view";
//import { TouchableHighlight } from "react-native-gesture-handler";
import formatNumber from "format-number";
const numberFormat = formatNumber({
  round: 0,
});

export default function TeibanSelectScreen(props: any) {
  const navigation = useNavigation();
  const [teibans, setTeibans]: any = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [last_page, setLastPage] = useState(-1);
  const [filter, setFilter] = useState(null);

  async function loadNext() {
    if (page == last_page) return;

    let filter = await window.storage
      .load({
        key:
          "Teiban" + (props.route.params.type ? "Order" : "Invoice") + "Filter",
      })
      .catch((err: any) => null);
    setFilter(filter);

    let params: { page: number; type: number; filter?: any } = {
      page: page + 1,
      type: props.route.params.type,
    };
    if (filter) {
      params.filter = filter;
    }
    console.log(params);
    window
      .axios({
        method: "get",
        url: window.baseUrl + "/teiban",
        params,
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then(({ data }: any) => {
        if (data.data) {
          console.log(data.data);
          let tmp: any = teibans.slice();
          data.data.forEach((item: any, index: number) => {
            data.data[index].checked = false;
          });
          setTeibans(tmp.concat(data.data));
          setPage(data.current_page);
          setLastPage(data.last_page);
        }
      })
      .catch((err: any) => {
        window.functions.logout();
      });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      window.functions.openFilter = () => {
        navigation.navigate(
          (props.route.params.type ? "Order" : "Invoice") +
            "TeibanFilterScreen",
          {
            target: props.route.params.type ? "Order" : "Invoice",
            backScreen:
              (props.route.params.type ? "Order" : "Invoice") +
              "TeibanSelectScreen",
          }
        );
      };
      window.functions.reloadList = () => {
        setTeibans([]);
        setPage(0);
        setLastPage(-1);
        loadNext();
      };
      if (window.reloadFlag) {
        window.reloadFlag = false;
        setTeibans([]);
        setPage(0);
        setLastPage(-1);
        loadNext();
      }
    });

    loadNext();
  }, []);

  function renderItem({ item, index }: any) {
    const stateColor: string[] = ["#a0d8ef", "#9ae69a", "#f19ca7", "#bdafdb"];
    return (
      <TouchableOpacity
        activeOpacity={1}
        style={{
          width: "100%",
          height: 90,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
          flexDirection: "row",
          backgroundColor: "#fff",
          //paddingLeft: 10,
        }}
        onPress={() => {
          if (item.type == 0) {
            window.navi.navigate("InvoiceDetailScreen", {
              id: 0,
              teiban: item.id,
            });
          } else {
            window.navi.navigate("OrderDetailScreen", {
              id: 0,
              teiban: item.id,
            });
          }
        }}
      >
        <View
          style={{ width: 50, justifyContent: "center", alignItems: "center" }}
        >
          <CheckBox
            checked={item.checked}
            onPress={() => {
              let tmp: any = teibans.slice();
              tmp[index].checked = !teibans[index].checked;
              setTeibans(tmp);
            }}
          />
        </View>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            width: "*",
          }}
        >
          <View>
            <Text style={{ fontSize: 12 }}>{item.to_org_name}</Text>
          </View>
          <View style={{}}>
            <Text
              style={{
                width: window.width - 80,
                fontSize: 16,
                fontWeight: "bold",
              }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {item.work}
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: 14, color: "#f00" }}>
              {numberFormat(Number(item.total))}
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: 12, color: "#333" }}>
              最終請求日：{item.last_billing_dt ? item.last_billing_dt : "なし"}
            </Text>
          </View>
        </View>
        <View
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            width: 10,
            paddingRight: 10,
            flexDirection: "row",
            marginLeft: "auto",
          }}
        >
          <Text style={{ paddingLeft: 3 }}>{">"}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  window.functions.teibanAtOnce = () => {
    let type = props.route.params.type;
    let selected: any = [];
    if (teibans) {
      teibans.forEach((item: any, index: number) => {
        if (item.checked) {
          selected.push(item.id);
        }
      });
    }
    if (selected.length > 0) {
      let params: { teiban_ids: any } = {
        teiban_ids: selected,
      };
      window
        .axios({
          method: "post",
          url:
            window.baseUrl +
            (props.route.params.type == 0
              ? "/invoice/storeAtOnce"
              : "/order/storeAtOnce"),
          params,
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          alert("一括登録しました");
        })
        .catch((err: any) => {
          window.functions.logout();
        });
    } else {
      alert("定番を選択してください");
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          height: 25,
          backgroundColor: "#3cb371",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 14, color: "#fff" }}>
          定番({props.route.params.type ? "発注" : "請求"})
        </Text>
      </View>
      {filter && (
        <View
          style={{
            height: 18,
            backgroundColor: "#008000",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "#fff", fontSize: 14 }}>フィルタ中</Text>
        </View>
      )}
      <SwipeListView
        useFlatList={true}
        disableRightSwipe
        disableLeftSwipe
        style={{ width: "100%" }}
        keyExtractor={keyExtractor}
        data={teibans}
        renderItem={renderItem}
        onRefresh={() => {
          setIsFetching(true);
        }}
        refreshing={isFetching}
        onEndReached={() => {
          loadNext();
        }}
        onEndReachedThreshold={0.2}
        leftOpenValue={0}
        rightOpenValue={0}
        onRowOpen={(rowKey, rowMap) => {
          setTimeout(() => {
            if (rowMap[rowKey]) rowMap[rowKey].closeRow();
          }, 2000);
        }}
      />
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});
