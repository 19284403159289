import * as React from "react";
const DatePicker = require("react-mobile-datepicker");
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  TextInput,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Modal from "react-modal";

import {
  Input,
  Button,
  CheckBox,
  ButtonGroup,
  Badge,
} from "react-native-elements";
const moment = require("moment");
import formatNumber from "format-number";
import { block } from "react-native-reanimated";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { preventAutoHide } from "expo-splash-screen";
const numberFormat = formatNumber({
  round: 0,
});

export default function InvoiceDetailScreen(props: any) {
  const { navigation, functions } = props;

  const [billing_date, setBillingDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [to_org, setToOrg] = useState(0);
  const [from_org, setFromOrg] = useState(0);
  const [to_org_name, setToOrgName] = useState("未選択");
  const [from_org_name, setFromOrgName] = useState("未選択");
  const [work, setWork] = useState("");
  const [work_type_id, setWorkTypeId] = useState(0);
  const [work_type_name, setWorkTypeName] = useState("未選択");
  const [unit_price, setUnitPrice] = useState("0");
  const [quantity_type, setQuantityType] = useState("");
  const [quantity, setQuantity] = useState("0");
  const [total, setTotal] = useState("0");
  const [state, setState] = useState(0);
  const [state_label, setStateLabel] = useState("");
  const [draft, setDraft] = useState(0);
  const [errstr, setErrstr] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [listHeight, setListHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dt, setDt] = useState(new Date());
  const [own, setOwn] = useState(false);
  const [teiban_id, setTeibanId] = useState(0);

  const [errWork, setErrWork] = useState("");
  const [errUnitPrice, setErrUnitPrice] = useState("");
  const [errQuantity, setErrQuantity] = useState("");
  const [errToOrg, setErrToWork] = useState("");
  const [errFromOrg, setErrFromWork] = useState("");
  const [errWorkType, setErrWorkType] = useState("");

  const [props_id, setPropsId] = useState(props.route.params.id);
  const [props_teiban, setPropsTeiban] = useState(props.route.params.teiban);
  const [unread, setUnread] = useState(0);

  const [isWorkModal, setIsWorkModal] = useState(false);
  const [work_history, setWorkHistory] = useState([]);

  const stateColor: string[] = [
    "#a0d8ef",
    "#9ae69a",
    "#f19ca7",
    "#bdafdb",
    "#f4a460",
  ];

  function handleClick() {
    setIsPickerOpen(true);
  }
  function handleCancel() {
    setIsPickerOpen(false);
  }
  function handleSelect(tm: any) {
    setDt(tm);
    setBillingDate(moment(tm).format("YYYY-MM-DD"));
    setIsPickerOpen(false);
  }

  function validate() {
    let flag = true;

    //仕事
    if (work == "") {
      setErrWork("入力してください");
      flag = false;
    } else {
      setErrWork("");
    }
    //単価
    if (unit_price == "") {
      setErrUnitPrice("入力してください");
      flag = false;
    } else if (!isFinite(parseInt(unit_price))) {
      setErrUnitPrice("数値で入力してください");
      flag = false;
    } else if (parseInt(unit_price) <= 0) {
      setErrUnitPrice("1以上で入力してください");
      flag = false;
    } else {
      setErrUnitPrice("");
    }
    //数量
    if (quantity == "") {
      setErrQuantity("入力してください");
      flag = false;
    } else if (!isFinite(parseInt(quantity))) {
      setErrQuantity("数値で入力してください");
      flag = false;
    } else if (parseInt(quantity) <= 0) {
      setErrQuantity("1以上で入力してください");
      flag = false;
    } else {
      setErrQuantity("");
    }
    //請求先
    if (to_org == 0) {
      setErrToWork("選択してください");
      flag = false;
    } else {
      setErrToWork("");
    }
    //請求元
    if (from_org == 0) {
      setErrFromWork("選択してください");
      flag = false;
    } else {
      setErrFromWork("");
    }
    //仕事種別
    if (work_type_id == 0) {
      setErrWorkType("選択してください");
      flag = false;
    } else {
      setErrWorkType("");
    }

    return flag;
  }

  useEffect(() => {
    //setIsLoading(true);

    if (props_id > 0 || (props_id == 0 && props_teiban > 0)) {
      let url =
        props_teiban > 0
          ? window.baseUrl + "/teiban/" + props_teiban
          : window.baseUrl + "/invoice/" + props_id;
      window
        .axios({
          method: "get",
          url,
          data: {},
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          if (props_teiban > 0) {
            setToOrg(data.to_org);
            setFromOrg(data.from_org);
            setToOrgName(data.to_org_name);
            setFromOrgName(data.from_org_name);
            setWorkTypeId(data.work_type_id);
            setWorkTypeName(data.work_type_name);
            setWork(data.work ? data.work : "");
            setUnitPrice(data.unit_price);
            setQuantity(data.quantity);
            setTotal(data.total);
            if (props_teiban > 0) setTeibanId(props_teiban);
            setOwn(true);
          } else {
            setBillingDate(data.billing_date);
            setToOrg(data.to_org);
            setFromOrg(data.from_org);
            setToOrgName(data.to_org_name);
            setFromOrgName(data.from_org_name);
            setWorkTypeId(data.work_type_id);
            setWorkTypeName(data.work_type_name);
            setWork(data.work ? data.work : "");
            setUnitPrice(data.unit_price);
            setQuantity(data.quantity);
            setTotal(data.total);
            setStateLabel(data.state_label);
            setState(data.state);
            setDt(new Date(data.billing_date));
            setDraft(data.draft);
            setOwn(data.own);
            setTeibanId(data.teiban_id);
          }
        })
        .catch((err: any) => {
          window.functions.logout();
        });

      if (props_id > 0) {
        window
          .axios({
            method: "get",
            url: window.baseUrl + "/comment?type=0&target_id=" + props_id,
            headers: { Authorization: "Bearer " + window.accessToken },
          })
          .then(({ data }: any) => {
            if (data) {
              console.log(data);
              setComments(data);
              //
              let ur = 0;
              data.forEach((item: any) => {
                if (!item.read_flag) {
                  ur++;
                }
              });
              setUnread(ur);
              //
              setSelectedIndex(0);
              setIsLoading(false);
            }
          })
          .catch((err: any) => {
            window.functions.logout();
          });
      } else {
        setIsLoading(false);
      }
    } else {
      window
        .axios({
          method: "post",
          url: window.baseUrl + "/invoice/last",
          data: {},
          headers: { Authorization: "Bearer " + window.accessToken },
        })
        .then(({ data }: any) => {
          if (data) {
            console.log(data);
            if (data.invoice) {
              let { invoice } = data;
              setToOrg(invoice.to_org);
              setToOrgName(invoice.to_org_name);
              setFromOrg(invoice.from_org);
              setFromOrgName(invoice.from_org_name);
            }
            setIsLoading(false);
          }
        })
        .catch((err: any) => {
          window.functions.logout();
        });
      setOwn(true);
    }
  }, []);

  useEffect(() => {
    if (
      !(
        props_id > 0 &&
        (((state == 1 || state == 3) && draft != 1) || own != true)
      )
    ) {
      if (
        to_org > 0 &&
        from_org > 0 &&
        work_type_id > 0
        //work == "" &&
        //unit_price == "0" &&
        //quantity == "0"
      ) {
        window
          .axios({
            method: "post",
            url: window.baseUrl + "/invoice/history",
            data: {
              to_org,
              from_org,
              work_type_id,
            },
            headers: { Authorization: "Bearer " + window.accessToken },
          })
          .then(({ data }: any) => {
            if (data) {
              console.log("works: ", data);
              if (data.length == 1) {
                let dat = data[0];
                setWork(dat.work);
                setUnitPrice(dat.unit_price);
                setQuantity(dat.quantity);
                setTotal(dat.total);
              } else if (data.length > 1) {
                setWorkHistory(data);
                setIsWorkModal(true);
              } else {
                setWorkHistory([]);
                setIsWorkModal(false);
              }
            }
          })
          .catch((err: any) => {
            window.functions.logout();
          });
      }
    }
  }, [to_org, from_org, work_type_id]);

  const component1 = () => <Text>請求内容</Text>;
  const component2 = () => (
    <Text>
      コメント{comments.length > 0 ? "(" + comments.length + ")" : ""}{" "}
      {unread > 0 && <Badge value={unread} status="error" />}
    </Text>
  );
  const buttons = [{ element: component1 }, { element: component2 }];

  let work_items: any = [];
  work_history.forEach((item: any, index) => {
    work_items.push(
      <TouchableOpacity
        key={index}
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
          paddingBottom: 5,
          paddingTop: 5,
        }}
        onPress={() => {
          setWork(item.work);
          setUnitPrice(item.unit_price);
          setQuantity(item.quantity);
          setTotal(item.total);
          setIsWorkModal(false);
        }}
      >
        <Text style={{ fontSize: 16 }}>{item.work}</Text>
        <Text style={{ fontSize: 12, color: "#333" }}>
          単価: {item.unit_price} 個数: {item.quantity}
        </Text>
      </TouchableOpacity>
    );
  });

  return (
    <View style={styles.container}>
      <View style={{ width: "100%" }}>
        {props_id > 0 && draft != 1 && (
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: stateColor[state],
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 14 }}>
              {state_label}
            </Text>
          </View>
        )}
        {draft == 1 && (
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: "#ccc",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 14 }}>下書き</Text>
          </View>
        )}
        {props_id > 0 && draft != 1 && (
          <ButtonGroup
            onPress={(index) => {
              if (index == 1 && unread > 0) window.reloadFlag = true;
              window
                .axios({
                  method: "get",
                  url:
                    window.baseUrl +
                    "/comment?type=0&target_id=" +
                    props_id +
                    (index == 1 ? "&read=true" : ""),
                  headers: { Authorization: "Bearer " + window.accessToken },
                })
                .then(({ data }: any) => {
                  if (data) {
                    console.log(data);
                    setComments(data);
                    //
                    let ur = 0;
                    data.forEach((item: any) => {
                      if (!item.read_flag) {
                        ur++;
                      }
                    });
                    setUnread(ur);
                    //
                    setSelectedIndex(index);
                  }
                })
                .catch((err: any) => {});
            }}
            selectedIndex={selectedIndex}
            buttons={buttons}
            containerStyle={{ borderWidth: 0, height: 40 }}
            innerBorderStyle={{ width: 0 }}
            selectedButtonStyle={{
              borderBottomWidth: 2,
              borderBottomColor: "rgb(47, 149, 220)",
              backgroundColor: "white",
            }}
          />
        )}
      </View>
      <ScrollView
        style={{
          width: "100%",
          padding: 10,
          display: selectedIndex == 0 || selectedIndex == -1 ? "flex" : "none",
        }}
      >
        {own != true && (
          <View
            style={{
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <View style={styles.itemViewStyle}>
              <Text style={{}}>請求日</Text>
              <Text style={styles.itemStyle}>{billing_date}</Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>請求先</Text>
              <Text style={styles.itemStyle}>{to_org_name}</Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>請求元</Text>
              <Text style={styles.itemStyle}>{from_org_name}</Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>分類（仕事）</Text>
              <Text style={styles.itemStyle}>{work_type_name}</Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>仕事</Text>
              <Text style={styles.itemStyle}>{work}</Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>単価／時給</Text>
              <Text style={styles.itemStyle}>
                {numberFormat(Number(unit_price))}
              </Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>数量／時間</Text>
              <Text style={styles.itemStyle}>
                {numberFormat(Number(quantity))}
              </Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>合計</Text>
              <Text style={styles.itemStyle}>
                {numberFormat(Number(total))}
              </Text>
            </View>
          </View>
        )}
        {own == true && (
          <View
            style={{
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <View style={styles.itemViewStyle}>
              <Text style={{}}>請求日</Text>
              <Text style={styles.itemStyleEdit} onPress={handleClick}>
                {billing_date}
                <View style={{ position: "absolute", right: 0 }}>
                  <Text style={{ fontWeight: "normal" }}>＞</Text>
                </View>
              </Text>
              <DatePicker
                value={dt}
                isOpen={isPickerOpen}
                onSelect={handleSelect}
                onCancel={handleCancel}
                confirmText={"選択"}
                cancelText={"閉じる"}
              />
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>請求先</Text>
              <TouchableOpacity
                onPress={() => {
                  window.navi.navigate("SelectOrganisationScreen", {
                    id: to_org,
                    setOrg: setToOrg,
                    setOrgName: setToOrgName,
                    backScreen: "InvoiceDetailScreen",
                    type: 0,
                    mode: "to_org",
                  });
                }}
              >
                <Text style={styles.itemStyleEdit}>
                  {to_org_name}
                  <View style={{ position: "absolute", right: 0 }}>
                    <Text style={{ fontWeight: "normal" }}>＞</Text>
                  </View>
                </Text>
              </TouchableOpacity>
              <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
                {errToOrg}
              </Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>請求元</Text>
              <TouchableOpacity
                onPress={() => {
                  window.navi.navigate("SelectOrganisationScreen", {
                    id: from_org,
                    setOrg: setFromOrg,
                    setOrgName: setFromOrgName,
                    backScreen: "InvoiceDetailScreen",
                    range: "belong",
                    type: 0,
                    mode: "from_org",
                  });
                }}
              >
                <Text style={styles.itemStyleEdit}>
                  {from_org_name}
                  <View style={{ position: "absolute", right: 0 }}>
                    <Text style={{ fontWeight: "normal" }}>＞</Text>
                  </View>
                </Text>
              </TouchableOpacity>
              <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
                {errFromOrg}
              </Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>分類（仕事）</Text>
              <TouchableOpacity
                onPress={() => {
                  window.navi.navigate("SelectWorkTypeScreen", {
                    id: work_type_id,
                    setWorkTypeId,
                    setWorkTypeName,
                    backScreen: "InvoiceDetailScreen",
                    type: 0,
                  });
                }}
              >
                <Text style={styles.itemStyleEdit}>
                  {work_type_name}
                  <View style={{ position: "absolute", right: 0 }}>
                    <Text style={{ fontWeight: "normal" }}>＞</Text>
                  </View>
                </Text>
              </TouchableOpacity>
              <Text style={{ color: "rgb(255, 25, 12)", fontSize: 12 }}>
                {errWorkType}
              </Text>
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>仕事</Text>
              <Input
                style={styles.itemStyleEdit}
                value={work}
                onChangeText={(value) => {
                  setWork(value);
                }}
                errorMessage={errWork}
              />
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>単価／時給</Text>
              <Input
                keyboardType={"number-pad"}
                style={styles.itemStyleEdit}
                value={unit_price}
                errorMessage={errUnitPrice}
                onChangeText={(value: any) => {
                  setUnitPrice(value);
                  let val = parseInt(value) * parseInt(quantity);
                  setTotal(val.toString());
                }}
                onFocus={() => {
                  if (unit_price == "0") {
                    setUnitPrice("");
                  }
                }}
                onBlur={() => {
                  if (unit_price == "") {
                    setUnitPrice("0");
                  }
                }}
              />
            </View>
            <View style={styles.itemViewStyle}>
              <Text style={{}}>数量／時間</Text>
              <Input
                keyboardType={"number-pad"}
                style={styles.itemStyleEdit}
                value={quantity}
                errorMessage={errQuantity}
                onChangeText={(value: any) => {
                  setQuantity(value);
                  let val = parseInt(unit_price) * parseInt(value);
                  setTotal(val.toString());
                }}
                onFocus={() => {
                  if (quantity == "0") {
                    setQuantity("");
                  }
                }}
                onBlur={() => {
                  if (quantity == "") {
                    setQuantity("0");
                  }
                }}
              />
            </View>

            <View style={styles.itemViewStyle}>
              <Text style={{}}>合計</Text>
              <Text style={styles.itemStyle}>
                <Text style={{ ...styles.itemStyle }}>
                  {numberFormat(Number(total))}
                </Text>
              </Text>
            </View>

            {/*<View style={styles.itemViewStyle}>
              <Text style={{}}>コメント</Text>
              <TextInput
                multiline
                numberOfLines={2}
                style={{
                  width: "100%",
                  height: 32,
                  borderWidth: 1,
                  borderColor: "#ddd",
                  borderRadius: 3,
                  marginBottom: 20,
                }}
                onChangeText={(value) => {
                  setComment(value);
                }}
                value={comment}
              />
              </View>*/}

            <View style={{ marginBottom: 20 }}>
              <Text style={{ color: "#0000FF" }}>
                「{to_org_name}」へ{"\n"}「{from_org_name}」が{"\n"}「
                {work_type_name}」を
              </Text>
            </View>

            {props_id > 0 && draft == 0 && (
              <Button
                title={state != 2 ? "修正する" : "再提出する"}
                titleStyle={{ fontSize: 17 }}
                disabled={false}
                onPress={() => {
                  if (!validate()) return;
                  if (
                    confirm(
                      state != 2
                        ? "修正してもよろしいですか？"
                        : "再提出してもよろしいですか？"
                    )
                  ) {
                    window
                      .axios({
                        method: "put",
                        url: window.baseUrl + "/invoice/" + props_id,
                        data: {
                          id: props_id,
                          billing_date,
                          to_org,
                          from_org,
                          work_type_id,
                          work,
                          unit_price,
                          quantity,
                          total,
                          state: state == 2 ? 4 : state,
                          draft: 0,
                        },
                        headers: {
                          Authorization: "Bearer " + window.accessToken,
                        },
                      })
                      .then((ret: any) => {
                        window.reloadFlag = true;
                        navigation.navigate("InvoiceScreen");
                      })
                      .catch((err: any) => {
                        window.functions.logout();
                      });
                  }
                }}
              />
            )}
            {props_id > 0 && draft == 1 && (
              <Button
                title="請求する"
                titleStyle={{ fontSize: 17 }}
                disabled={false}
                onPress={() => {
                  if (!validate()) return;
                  if (confirm("請求してもよろしいですか？")) {
                    window
                      .axios({
                        method: "put",
                        url: window.baseUrl + "/invoice/" + props_id,
                        data: {
                          id: props_id,
                          billing_date,
                          to_org,
                          from_org,
                          work_type_id,
                          work,
                          unit_price,
                          quantity,
                          total,
                          state: 0,
                          draft: 0,
                          teiban_id,
                        },
                        headers: {
                          Authorization: "Bearer " + window.accessToken,
                        },
                      })
                      .then((ret: any) => {
                        window.reloadFlag = true;
                        navigation.navigate("InvoiceScreen");
                      })
                      .catch((err: any) => {
                        window.functions.logout();
                      });
                  }
                }}
              />
            )}
            {props_id == 0 && draft == 0 && (
              <Button
                title="請求する"
                titleStyle={{ fontSize: 17 }}
                disabled={false}
                onPress={() => {
                  if (!validate()) return;
                  if (confirm("請求してもよろしいですか？")) {
                    window
                      .axios({
                        method: "post",
                        url: window.baseUrl + "/invoice",
                        data: {
                          billing_date,
                          to_org,
                          from_org,
                          work_type_id,
                          work,
                          unit_price,
                          quantity,
                          total,
                          state: 0,
                          comment,
                          draft: 0,
                          teiban_id,
                        },
                        headers: {
                          Authorization: "Bearer " + window.accessToken,
                        },
                      })
                      .then((ret: any) => {
                        window.reloadFlag = true;
                        if (teiban_id == 0) {
                          navigation.navigate("InvoiceScreen");
                        } else {
                          navigation.navigate("InvoiceTeibanSelectScreen");
                        }
                      })
                      .catch((err: any) => {
                        window.functions.logout();
                      });
                  }
                }}
              />
            )}
            {props_id > 0 && draft == 1 && (
              <Button
                title="下書き保存する"
                type="outline"
                titleStyle={{ fontSize: 15 }}
                buttonStyle={{ height: 30 }}
                style={{ marginTop: 10 }}
                onPress={() => {
                  window
                    .axios({
                      method: "put",
                      url: window.baseUrl + "/invoice/" + props_id,
                      data: {
                        id: props_id,
                        billing_date,
                        to_org,
                        from_org,
                        work_type_id,
                        work,
                        unit_price,
                        quantity,
                        total,
                        state: 0,
                        comment,
                        draft: 1,
                        teiban_id,
                      },
                      headers: {
                        Authorization: "Bearer " + window.accessToken,
                      },
                    })
                    .then((ret: any) => {
                      window.reloadFlag = true;
                      navigation.navigate("InvoiceScreen");
                    })
                    .catch((err: any) => {
                      window.functions.logout();
                    });
                }}
              />
            )}
            {props_id == 0 && draft == 0 && (
              <Button
                title="下書き保存する"
                type="outline"
                titleStyle={{ fontSize: 15 }}
                buttonStyle={{ height: 30 }}
                style={{ marginTop: 10 }}
                onPress={() => {
                  window
                    .axios({
                      method: "post",
                      url: window.baseUrl + "/invoice",
                      data: {
                        billing_date,
                        to_org,
                        from_org,
                        work_type_id,
                        work,
                        unit_price,
                        quantity,
                        total,
                        state: 0,
                        comment,
                        draft: 1,
                        teiban_id,
                      },
                      headers: {
                        Authorization: "Bearer " + window.accessToken,
                      },
                    })
                    .then((ret: any) => {
                      window.reloadFlag = true;
                      navigation.navigate("InvoiceScreen");
                    })
                    .catch((err: any) => {
                      window.functions.logout();
                    });
                }}
              />
            )}
            {props_id > 0 && state == 1 && own == true && teiban_id == 0 && (
              <View style={{ marginTop: 10 }}>
                <Button
                  title="同じ内容で請求する"
                  titleStyle={{ fontSize: 17 }}
                  disabled={false}
                  buttonStyle={{ backgroundColor: "#3cb371" }}
                  onPress={() => {
                    setPropsId(0);
                    setDt(new Date());
                    setBillingDate(moment().format("YYYY-MM-DD"));
                  }}
                />
                <Button
                  title="定番に登録する"
                  type="outline"
                  titleStyle={{ fontSize: 15, color: "#3cb371" }}
                  buttonStyle={{ height: 30, borderColor: "#3cb371" }}
                  style={{ marginTop: 10 }}
                  onPress={() => {
                    window
                      .axios({
                        method: "post",
                        url: window.baseUrl + "/teiban",
                        data: {
                          type: 0,
                          billing_date,
                          to_org,
                          from_org,
                          work_type_id,
                          work,
                          unit_price,
                          quantity,
                          total,
                          source_id: props_id,
                        },
                        headers: {
                          Authorization: "Bearer " + window.accessToken,
                        },
                      })
                      .then((ret: any) => {
                        //window.reloadFlag = true;
                        //navigation.navigate("InvoiceScreen");
                        alert("定番に登録しました");
                      })
                      .catch((err: any) => {
                        window.functions.logout();
                      });
                  }}
                />
              </View>
            )}
            {props_id > 0 && (
              <Button
                title="削除する"
                titleStyle={{ fontSize: 15 }}
                disabled={false}
                buttonStyle={{
                  height: 30,
                  backgroundColor: "#f00",
                  marginTop: 30,
                }}
                onPress={() => {
                  if (!confirm("削除してもよいですか？")) return;
                  window
                    .axios({
                      method: "delete",
                      url: window.baseUrl + "/invoice/" + props_id,
                      headers: {
                        Authorization: "Bearer " + window.accessToken,
                      },
                    })
                    .then(() => {
                      window.reloadFlag = true;
                      navigation.navigate("InvoiceScreen");
                    });
                }}
              />
            )}
          </View>
        )}
      </ScrollView>
      {/* コメント画面 */}
      <View
        style={{
          flex: 1,
          width: "100%",
          display: selectedIndex == 1 || selectedIndex == -1 ? "flex" : "none",
        }}
      >
        <View
          style={{ flex: 1, marginBottom: 70 }}
          onLayout={(info) => {
            setListHeight(info.nativeEvent.layout.height);
          }}
        >
          <FlatList
            inverted
            style={{
              width: "100%",
              position: "absolute",
              maxHeight: listHeight,
            }}
            keyExtractor={keyExtractor}
            data={comments}
            renderItem={renderItem}
            ref={(ref) => {
              window.commentList = ref;
            }}
          />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: 70,
            bottom: 0,
            left: 0,
            backgroundColor: "#fff",
            flexDirection: "row",
            borderTopColor: "#eee",
            borderTopWidth: 1,
          }}
        >
          <View
            style={{
              flex: 1,
              height: "100%",
              backgroundColor: "#fff",
              padding: 5,
              paddingLeft: 20,
            }}
          >
            <TextInput
              multiline
              numberOfLines={2}
              style={{
                flex: 1,
                borderWidth: 1,
                borderColor: "#ddd",
                borderRadius: 3,
              }}
              onChangeText={(value) => {
                setComment(value);
              }}
              value={comment}
            />
          </View>
          <View
            style={{
              width: 70,
              height: "100%",
              backgroundColor: "#fff",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              title="送信"
              titleStyle={{ fontSize: 14 }}
              onPress={() => {
                if (comment != "" && comment != null) {
                  window
                    .axios({
                      method: "post",
                      url: window.baseUrl + "/comment",
                      data: {
                        type: 0,
                        target_id: props_id,
                        comment: comment,
                      },
                      headers: {
                        Authorization: "Bearer " + window.accessToken,
                      },
                    })
                    .then(({ data }: any) => {
                      setComments(data);
                      //
                      let ur = 0;
                      data.forEach((item: any) => {
                        if (!item.read_flag) {
                          ur++;
                        }
                      });
                      setUnread(ur);
                      //
                      setComment("");
                      window.reloadFlag = true;
                    })
                    .catch((err: any) => {
                      window.functions.logout();
                    });
                }
              }}
            />
          </View>
        </View>
      </View>
      {isLoading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            flex: 1,
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
      <Modal
        isOpen={isWorkModal}
        onRequestClose={() => {
          setIsWorkModal(false);
        }}
        shouldCloseOnOverlayClick={true}
        style={{
          content: {
            position: "absolute",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
            margin: "auto",
            height: 250,
          },
        }}
      >
        <View style={{ flex: 1 }}>{work_items}</View>
      </Modal>
    </View>
  );
}

function keyExtractor(item: any, index: any) {
  return item.id.toString();
}

function renderItem({ item }: any) {
  return (
    <View
      style={{
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
        backgroundColor: item.read_flag == false ? "#ffe4e1" : "#fff",
      }}
    >
      <Text style={{ fontWeight: "bold", marginBottom: 3 }}>
        {moment(item.created_at).format("YYYY-MM-DD HH:mm")}　{item.user_name}
      </Text>
      <Text>{item.comment}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
