import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  useWindowDimensions,
  View,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Input, Button, CheckBox, Text } from "react-native-elements";
import Select from "react-select";
import { FontAwesome } from "@expo/vector-icons";

import { useNavigation } from "@react-navigation/native";

export default function SelectWorkTypeScreen(props: any) {
  const navigation = useNavigation();
  const [worktypes, setWorkTypes] = useState([]);
  const [parents, setParents] = useState([]);
  const [parent_value, setParentValue] = useState({});

  async function init() {
    let { data: wkt } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/worktype/" +
        props.route.params.id +
        "?type=" +
        props.route.params.type,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (wkt && wkt.parent) {
      setParentValue({
        label: wkt.parent.name,
        value: wkt.parent.id,
      });
    }

    let { data } = await window.axios({
      method: "get",
      url: window.baseUrl + "/worktype?id=0&type=" + props.route.params.type,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    let newList = [];
    if (data) {
      newList = data.map(function (item: any) {
        return { label: item.name, value: item.id };
      });
      setParents(newList);
    }

    setParentValue({
      label: newList[0].label,
      value: newList[0].value,
    });
    let { data: wkt_list } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/worktype?id=" +
        newList[0].value +
        "&type=" +
        props.route.params.type,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (wkt_list) {
      setWorkTypes(wkt_list);
    }
  }

  async function changeWorktype(id: number) {
    let { data: wkt_list } = await window.axios({
      method: "get",
      url:
        window.baseUrl +
        "/worktype?id=" +
        id +
        "&type=" +
        props.route.params.type,
      headers: { Authorization: "Bearer " + window.accessToken },
    });
    if (wkt_list) {
      setWorkTypes(wkt_list);
    }
  }

  useEffect(() => {
    init();
    window.functions.unset = () => {
      props.route.params.setWorkTypeId(0);
      props.route.params.setWorkTypeName("未選択");
      navigation.navigate(props.route.params.backScreen);
    };
  }, []);

  function keyExtractor(item: any, index: any) {
    return item.id.toString();
  }

  function renderItem({ item }: any) {
    return (
      <View
        style={{
          height: 50,
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            props.route.params.setWorkTypeId(item.id);
            props.route.params.setWorkTypeName(item.label_name);
            navigation.navigate(props.route.params.backScreen);
          }}
        >
          <Text style={{ fontSize: 12, color: "#aaa" }}>
            {item.parent_name}
          </Text>
          <Text style={{ fontSize: 17 }}>{item.name}</Text>
          {item.id == props.route.params.id && (
            <FontAwesome
              name={"check"}
              size={24}
              style={{
                color: "rgb(32, 137, 220)",
                position: "absolute",
                right: 10,
                top: 10,
              }}
            />
          )}
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={{ width: "100%", padding: 5, zIndex: 3000 }}>
        <Select
          options={parents}
          onChange={(val: any) => {
            setParentValue(val);
            changeWorktype(val.value);
          }}
          value={parent_value}
        />
      </View>
      <FlatList
        style={{ width: "100%", backgroundColor: "#fff", marginTop: 5 }}
        keyExtractor={keyExtractor}
        data={worktypes}
        renderItem={renderItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
});
